/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import root from 'window-or-global';
import cx from 'classnames';
import { DealsModal } from '@/features/pdp/components/DealsModal';
import { RebateOnlyBadge } from '@/features/pdp/components/RebateOnlyBadge';
import { ProductCarousel } from '@/features/pdp/components/ProductCarousel';
import { Grid } from '../../Grid';
import { useRouter } from 'next/router';
import { Button } from '@/components/Button';
import azCommonStyles from '../../../theme/globals.module.scss';
import { FulfillmentOptions as FulfillmentOptionsMX } from '../../FulfillmentOptions';
import OldFulfillmentOptionsV2 from '../../OldFulfillmentOptionsV2';
import FulfillmentOptionsV2 from '../../FulfillmentOptionsV2';
import { SaveNowBadge } from '@/features/shelf/components/PageTemplates/Shelf/ResultsList/SaveNowBadge';
import { getFulfillmentOption } from '@/utils/getFulfillmentOption';
import { getMaxAvailQtyFromOptions } from '@/utils/itemHelpers/fulfillmentHelpers/getMaxAvailQtyFromOptions';
import { convertFulfillmentTypeToOrderType } from '@/utils/itemHelpers/lineItemHelpers/convertFulfillmentTypeToOrderType';
import { certonaPageType } from '@/constants/certonaPageType';
import { callToCertona } from '@/features/certona/api/callToCertona';
import { usePatchOrderItem } from '@/features/orders/api/patchOrderItem';
import type { ReduxState, VehicleSummary } from '@/types';
import type {
  ProductData,
  UseAddToCartWithMiniCartResult,
  AddToCartAnalyticsData,
} from '@/types/reduxStore/cartUpdate';
import FitmentButton from '../../AZCustomComponent/FitmentButton';
import { Label } from '@/features/i18n';
import { interpolateLabel } from '@/features/i18n/utils/interpolateLabel';
import { useLabels } from '@/hooks/useLabels';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { pageTypes } from '../../../constants/page';
import { itemDetail } from '../../../constants/images/itemDetail';
import { productInfoConstants } from '../../../constants/productInfoConstants';
import { cartConstants } from '../../../constants/cart';
import { routePaths } from '../../../constants/routePaths';
import { checkAvailabilityByGroupTypeId } from '@/features/orders/utils/checkAvailabilityByGroupTypeId';
import scrollToElem from '../../../utils/crossBrowserHelpers/scroll';
import getPosition from '../../../utils/crossBrowserHelpers/getPosition';
import {
  FULFILLMENT_METHODS,
  fulfillmentGroupTypeIds,
} from '../../../constants/fulfillmentConstants';
import CartButton, { type CartButtonRef } from '../../AZCustomComponent/CartButton';
import { ProductMetaLabel } from '../../ProductMetaLabel';
import NextImage from '@/components/NextImage';
import { DealsDrawerButton } from '@/features/deals/components/DrawerOpenButton/DrawerOpenButton';
import { YMMEModal } from '@/features/ymme/components/YMMEModal';
import { getFormattedPriceMultiplied, getStaticFormattedPrice } from '../../../utils/priceHelpers';
import styles from './styles.module.scss';
import LeftSectionSkeletonLoading from './SkeletonLoading/LeftSection';
import RightSectionSkeletonLoading from './SkeletonLoading/RightSection';
import type { InteractionLocation } from '@/types/analytics';
import { usePageSpecificData } from '@/utils/analytics/usePageSpecificData';
import { trackFulfillmentChange } from '@/utils/analytics/track/trackFulfillmentChange';
import { trackQuantityChange } from '@/utils/analytics/track/trackQuantityChange';
import { trackQuantityFulfillmentChange } from '@/utils/analytics/track/trackQuantityFulfillmentChange';
import { trackCustomizeSkuStart } from '@/utils/analytics/track/trackCustomizeSkuStart';
import { trackNearbyStoreUpdate } from '@/utils/analytics/track/trackNearbyStoreUpdate';
import { trackReadMoreLinkPdp } from '@/utils/analytics/track/trackReadMoreLinkPdp';
import { analyticsPDPReviewRating } from '../analytics/analyticsPDPReviewRating';
import { getProductStrikethroughAnalyticValue } from '../analytics/getProductStrikethroughAnalyticValue';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useStoreDetailsData } from '@/features/header/api/getStoreDetails';
import WarrantyBadge from '../../AZCustomComponent/WarrantyBadge';
import { sessionStorage } from '../../../utils/sessionStorage';
import { useFeatureFlag } from '@/features/globalConfig';
import {
  getProductSkuDetailsKey,
  getProductSkuDetailsFromCache,
} from '@/features/product/api/getProductSkuDetails';
import { type ProductSkuDetails } from '@/features/product';
import ProductAvailabilityInfoSkeleton from './ProductAvailabilityInfoSkeleton';
import { useQueryClient } from '@tanstack/react-query';
import { type ProductModel } from '@/api/types/products-types';
import { FulfillmentOptionButtonsV2 } from '@/components/FulfillmentOptionButtons/FulfillmentOptionButtonsV2';
import { useXMPreviewState } from '@/stores/XMPreviewState';
import {
  getSelectedFulfilmentID,
  getSelectedFulfilmentForSelectedQty,
  getFulfillmentId,
  getSelectedFulfillmentInCart,
  getAllAvailableQuantitiesForSku,
} from '@/utils/getFulfillmentOption';
import { MaxQuantityNotificationBlock } from '@/components/AZCustomComponent/MaxQuantityNotificationBlock';
import { SisterStoreContext } from '@/features/fulfillment/context/SisterStoreContext';
import dynamic from 'next/dynamic';
import ProgressModal from '@/components/AZCustomComponent/ProgressModal';
import usePrevious from '@/utils/usePrevious';
import { ProductCrossReference } from '@/features/shelf/components/PageTemplates/Shelf/ResultsList/ProductCrossReference';
import PdpPriceQuantity from './PdpPriceQuantity';
import SeoContent from './SeoContent';
import PdpTitleNotes from './PdpTitleNotes';
import PdpTechNotes from './PdpTechNotes';
import { clickTrack } from '@/utils/analytics/clickTrack';
import type { SkuDealsFromAPI } from '@/types/availability';
import { type OrderType } from '@/constants/orderTypes';
import { usePriceHiding } from '@/hooks/usePriceHiding';
import { getXFusionQueryId } from '@/utils/xFusionQueryIdHelpers';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { setInteractionLocation } from '@/features/ymme/utils/ymmeLocationHelper';
import {
  determineIndexButtonToBeSelected,
  determineOptionsToDisplayButtons,
} from '@/features/fulfillment/api/optionsToDisplay';

const LazySearchStoreModal = dynamic(
  () =>
    import('@/components/Store/StoreModal/SearchStoreModal').then((mod) => mod.SearchStoreModal),
  {
    loading: () => <ProgressModal noScroll />,
  }
);

type InitialProps = {
  changeFindProduct: () => void;
  showWarrantyModal: (a: string) => void;
  pdpAddToCartSuccess: boolean;
  addToCartButtonLoading: boolean;
  openConfigurableSku: () => void;
  preferredVehicle: VehicleSummary | undefined;
  hasVehicle: boolean;
  defaultFulfillmentOption: string;
  refetchProductData: () => Promise<any>;
  refetchSkuDetails: () => Promise<any>;
  productDetailsSuccess: boolean;
  skuDetailsDataLastUpdatedAt: any;
  setTrackQOHError: (a: boolean) => void;
  isBundleDealDrawerOpen: Function;
  count: number;
  setCount: Function;
  shippingType: typeof cartConstants.STOREORDER | typeof cartConstants.ONLINEORDER;
  setShippingType: Function;
  shippingTypeChanged: boolean;
  setShippingTypeChanged: React.Dispatch<React.SetStateAction<boolean>>;
  fulfillmentId: number;
  setFulfillmentId: (fulfillmentId: number) => void;
  setShouldOpenSpecsPanel: (shouldOpenSpecsPanel: boolean) => void;
  prevShippingType: string | undefined;
  shouldShowSkeleton: boolean;
  productDetailsUpdatedAt: number;
  setGroupId: (
    groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER
  ) => void;
  groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER;
  quantityInCart: number;
  setQuantityInCart: (quantityInCart: number) => void;
  setShowBundleDeals: (showBundleDeals: boolean) => void;
  retailPrice: number | string;
  setRetailPrice: (retailPrice: number | string) => void;
  storeChanged: boolean;
  setStoreChanged: (storeChanged: boolean) => void;
};

const mobile = 'mobile';
const tablet = 'tablet';

const labelMap = {
  lblUnit: 'label_confirmation_lineItem_Each',
  lblCoreText: 'pagecontent_cart_corePopup_CoreDetails',
  lblGoToCart: 'label_CategoryPage_body_GoToCart',
  lblAddToCart: 'button_ProductDisplayPage_body_AddToCart',
  lblAdd: 'label_MyAccountVehicle_maintenanceInterval_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblAddToCartDiscount: 'label_ShelfPage_AddToCartDiscount',
  lblSeeSavingsInCart: 'label_see_savings_in_cart',
  viewDealsItem: 'label_Deals_DealsPage_ViewSpecialDealsForThisItem',
  customizeYourFloorMats: 'label.configurableSku.customizeYourFloorMat',
  setVehicleFit: 'label.configurableSku.setVehicleFit',
  lblFindAtNearbyStore: 'label_DetailsPage_body_FindAtNearbyStore',
  lblSelectStore: 'label_DetailsPage_body_SelectStore',
  lblFits: 'label_fits_vehicle',
  lblDoesntFits: 'label_doesnt_fit_vehicle',
  lblNoVehicle: 'label_CategoryPage_body_VechicleSpecific',
  lblPlus: 'label_confirmation_lineItem_Plus',
  lblPartNo: 'label_ProductDisplayPage_body_PartNo',
  lblLocation: 'label_CategoryPage_body_Location',
  lblWarranty: 'label_cart_lineItem_Warranty',
  lblReadless: 'hyperlink_CategoryPage_body_ReadLess',
  lblApplicationSmall: 'label_CategoryPage_body_ApplicationSmall',
  lblWhatIsIt: 'label_CategoryPage_body_WhatIsIt',
  dealMakerCore: 'label_Deals_Dealmaker_Core',
  label_CategoryPage_body_Pinch2Fingers: 'label_CategoryPage_body_Pinch2Fingers',
  label_CategoryPage_body_ImageNotAvailable: 'label_CategoryPage_body_ImageNotAvailable',
  lblCheckNearbyStores: 'Label_CheckNearbyStores',
  lblDetailsPagebodySelectStore: 'label_DetailsPage_body_SelectStore',
  label_CategoryPage_body_SaveNow: 'label_CategoryPage_body_SaveNow',
  label_CategoryPage_body_PriceCut: 'label_CategoryPage_body_PriceCut',
  label_instant_saving: 'label_instant_saving',
  lblMailinRebate: 'Label_Mail_In_Rebate',
  lblProductPartNumber: 'label_DetailsPage_body_PartNo',
  label_DetailsPage_body_PartNumber: 'label_DetailsPage_body_PartNumber',
  lblSkuNumber: 'Label_Sku_Uppercase',
  labelSeeProductPrice: 'label_See_Product_Price',
  lblRegularPrice: 'label_regular_price',
  labelRegularPricePlusCore: 'label_regular_price_plus_core',
  labelRefundableCoreCharge: 'label_refundable_core_charge',
  lbllineItemWhatIsIt: 'label_cart_lineItem_WhatIsIt',
  lblCoreDetails: 'pagecontent_cart_corePopup_CoreDetails',
  label_update_cart: 'label_update_cart',
  lblSetVehicleToSeePricing: 'label_set_vehicle_to_see_pricing',
  lblRefundable: 'label_refundable',
  lblOrderSummaryCoreDeposit: 'label_cart_orderSummary_coreDeposit',
  label_check_nearby_stores: 'label_check_nearby_stores',
  label_for: 'label_checkout_shipping_For',
  labelUpdateCartToProductItems: 'label_update_cart_to_product_items',
  label_DetailsPage_body_Location: 'label_DetailsPage_body_Location',
};
const partNumberLabelBopusMX = (
  <span>
    <Label label="label_DetailsPage_body_PartNumber" />
    &nbsp;
  </span>
);

let pdpCheckFits: InteractionLocation = 'Check if it fits';

type ProductAvailabilityInfoProps = InitialProps & {
  productDetails: ProductModel | undefined;
  skuDetailsData: ProductSkuDetails | null;
  skuDealsData: SkuDealsFromAPI[] | undefined;
  shouldShowSkeleton: boolean;
  autoFocus?: boolean;
  productData: ProductData;
  onAddToCartCall: UseAddToCartWithMiniCartResult['addToCartCall'];
  oemBrandName?: string;
  oemPartNumber?: string;
  xFusionQueryId?: string;
  staticPrice: number;
};

export default function ProductAvailabilityInfo({
  productDetails,
  skuDetailsData,
  skuDealsData,
  shouldShowSkeleton,
  autoFocus,
  onAddToCartCall,
  productData,
  fulfillmentId,
  oemBrandName,
  oemPartNumber,
  xFusionQueryId,
  staticPrice,
  ...props
}: ProductAvailabilityInfoProps) {
  const router = useRouter();
  const locale = useLocale();
  const labels = useLabels(labelMap);
  const sisterStoreContext = useContext(SisterStoreContext);
  const { data: headerData } = useHeaderData();
  const catalogVehicleId = headerData?.vehicleMap?.catalogVehicleId ?? '';
  const { data: storeDetailsData } = useStoreDetailsData();
  const storeNumber = storeDetailsData?.storeNumber;
  const queryClient = useQueryClient();
  const { mutateAsync: patchOrderItem } = usePatchOrderItem();
  const { isFetchingLocation } = useSelector(({ appData }: ReduxState) => appData);
  const isBopusNewMsgApplicable = useFeatureFlag('IS_BOPUS_MSG_AVAILABLE_US') === 'true';
  const checkoutNearbyStoresEnabled = useFeatureFlag('CHECKOUT_NEARBY_STORES_ENABLED') === 'true';
  const dealDrawerPDPEnabled = useFeatureFlag('DEAL_DRAWER_PDP_ENABLED') === 'true';
  const strikeThroughPricingEnabled = useFeatureFlag('SHOW_STRIKE_THROUGH_PRICING') === 'true';
  const updateCartEnabled = useFeatureFlag('UPDATE_CART_ENABLED') === 'true';
  const isNewDealsApiEnabled = useFeatureFlag('IS_NEW_DEALS_API_ENABLED') === 'true';
  const hidePricing = usePriceHiding();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const { setPageSpecificData } = usePageSpecificData();

  const { workspaceId, previewDate } = useXMPreviewState();
  const isBopusEnabled = storeDetailsData?.bopusEnabled;
  const isBopusMexicoStoreEnabled = locale === countryCodes.mx && isBopusEnabled;

  const [productEdited, setProductEdited] = useState(false);
  const [prevGroupId, setPrevGroupId] = useState(0);
  const [prevGroupIdInCart, setPrevGroupIdInCart] = useState(0);
  const [pdpDealsModelOpen, setPdpDealsModelOpen] = useState(false);
  const [showYmme, setShowYmme] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);
  const [displayQuantityWarningMessage, setDisplayQuantityWarningMessage] = useState(false);
  const [disableRemoveButton, setDisableRemoveButton] = useState(false);
  const [payPalIsLoaded, setPayPalIsLoaded] = useState(false);
  const [productAddedToCart, setProductAddedToCart] = useState(false);

  const optionsThatAreDisplayed = determineOptionsToDisplayButtons(
    skuDetailsData?.fulfillmentOptions
  );
  const selectedItemId =
    optionsThatAreDisplayed[determineIndexButtonToBeSelected(props.shippingType, fulfillmentId)]
      ?.fulfillmentTypeIdOriginal;

  const [selectedFulfillmentTypeIdOriginal, setSelectedFulfillmentTypeIdOriginal] =
    useState<number>(0);

  useEffect(() => {
    setSelectedFulfillmentTypeIdOriginal(selectedItemId ?? 0);
  }, [selectedItemId]);

  const cartButtonRef = useRef<CartButtonRef>(null);
  const seeDealsButtonRef = useRef<HTMLButtonElement>(null);
  const prevProductDetailsUpdate = usePrevious(props.productDetailsUpdatedAt);
  const prevSkuDetailsUpdate = usePrevious(props.skuDetailsDataLastUpdatedAt);
  const prevSkuId = usePrevious(productDetails?.itemId);
  const handleDealsModal = () => {
    setPdpDealsModelOpen(true);
  };

  const configurableSku = skuDetailsData?.skuPricingAndAvailability.configurableSku ?? false;

  const price = configurableSku
    ? skuDetailsData?.skuPricingAndAvailability.lowestPrice
    : skuDetailsData?.skuPricingAndAvailability.retailPrice;

  const handleCloseModal = () => {
    setPdpDealsModelOpen(false);
  };

  const ratingView = () => {
    const ratingElement = document.getElementById('tab-reviews');

    if (ratingElement != null) {
      const client = getPosition(ratingElement);

      if (client.y !== 0) {
        scrollToElem(client.y - 75);
      }
    }
  };
  const skuAvailableQuantities = useRef(
    getAllAvailableQuantitiesForSku(skuDetailsData?.fulfillmentOptions)
  );
  const handleQuantityChange = useCallback(
    (
      quantity: number,
      quantityErrorMsg: boolean,
      showQuantityWarningMessage?: boolean,
      fulfillmentV2Id?: number
    ) => {
      const {
        groupId,
        setCount,
        setRetailPrice,
        quantityInCart,
        productDetailsSuccess,
        pdpAddToCartSuccess,
      } = props;
      const { sthQuantity } = skuAvailableQuantities.current;
      const storeData =
        skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions?.find(
          ({ groupTypeId }) => {
            return groupTypeId === groupId;
          }
        );
      const shouldShowMessage =
        fulfillmentId === FULFILLMENT_METHODS.STOREORDER
          ? storeData && storeData?.availableQty > 0
          : fulfillmentId === FULFILLMENT_METHODS.ONLINEORDER
          ? sthQuantity > 0
          : true;

      if (shouldShowMessage) {
        setDisplayQuantityWarningMessage(showQuantityWarningMessage ?? false);
      }
      const newQuantity = quantity ? Math.abs(quantity) : 1;
      setCount(newQuantity);
      setRetailPrice(getFormattedPriceMultiplied(staticPrice, newQuantity));
      const fulfillmentGroupSelectedInCart = skuDetailsData?.fulfillmentOptions?.find(
        (option) => option.selected === true
      );
      const fulfillmentSelectedInCart =
        fulfillmentGroupSelectedInCart?.groupFulfillmentOptions.find(
          (option) => option.selected === true
        );
      // Online order and Next day are the same button and this logic ensures it does not mark the two as different things
      const fulfillmentMethodChanged =
        Number(fulfillmentSelectedInCart?.fulfillmentTypeId) ==
          Number(fulfillmentV2Id ?? fulfillmentId) ||
        (Number(fulfillmentSelectedInCart?.fulfillmentTypeId) == 101 &&
          Number(fulfillmentV2Id ?? fulfillmentId) == 107) ||
        (Number(fulfillmentSelectedInCart?.fulfillmentTypeId) == 107 &&
          Number(fulfillmentV2Id ?? fulfillmentId) == 101);
      setProductEdited(
        quantityInCart > 0 &&
          !!shouldShowMessage &&
          (quantityInCart !== newQuantity || !fulfillmentMethodChanged) &&
          (productDetailsSuccess || pdpAddToCartSuccess)
      );
    },
    [
      fulfillmentId,
      props,
      skuAvailableQuantities,
      skuDetailsData?.fulfillmentOptions,
      skuDetailsData?.skuPricingAndAvailability,
      staticPrice,
    ]
  );

  const changeShippingTypeV2 = useCallback(
    (
      shippingType: string,
      groupId: number,
      maxAvailableQty: number,
      isSisterStoreAvailable?: boolean
    ) => {
      const { setShippingType, setGroupId, quantityInCart, count } = props;
      setPrevGroupId(props.groupId);
      setShippingType(shippingType);
      setGroupId(groupId);

      const fulfillmentSelectedInCart = skuDetailsData?.fulfillmentOptions?.find(
        (option) => option.selected === true
      );

      if (
        quantityInCart > 0 &&
        count === quantityInCart &&
        fulfillmentSelectedInCart?.groupId !== groupId
      ) {
        setProductEdited(true);
      } else if (
        quantityInCart > 0 &&
        count === quantityInCart &&
        fulfillmentSelectedInCart?.groupId === groupId
      ) {
        setProductEdited(false);
      } else {
        setProductEdited(quantityInCart > 0 || productAddedToCart);
      }
      if (isSisterStoreAvailable) {
        handleQuantityChange(1, false);
      } else if (count > maxAvailableQty && maxAvailableQty > 0) {
        handleQuantityChange(maxAvailableQty, false);
      }
    },
    [handleQuantityChange, productAddedToCart, props, skuDetailsData]
  );

  const changeShippingTypeV3 = (
    shippingType: typeof cartConstants.STOREORDER | typeof cartConstants.ONLINEORDER,
    groupId: typeof FULFILLMENT_METHODS.STOREORDER | typeof FULFILLMENT_METHODS.ONLINEORDER,
    maxAvailableQty: number,
    isSisterStoreAvailable?: boolean,
    fulfillmentTypeId?: number,
    fulfillmentTypeIdOriginal?: number
  ) => {
    setSelectedFulfillmentTypeIdOriginal(Number(fulfillmentTypeIdOriginal));
    const { quantityInCart, count } = props;
    setPrevGroupId(props.groupId);
    setDisplayQuantityWarningMessage(false);
    props.setShippingTypeChanged(true);

    const selectedFulfillmentId = getSelectedFulfilmentID(
      skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions ?? []
    );

    props.setShippingType(shippingType);
    props.setGroupId(groupId);
    if (fulfillmentTypeId) {
      handleFulfillmentIdUpdate(fulfillmentTypeId);
    }
    if (
      quantityInCart > 0 &&
      count === quantityInCart &&
      fulfillmentTypeId === FULFILLMENT_METHODS.NEXTDAY &&
      selectedFulfillmentId === FULFILLMENT_METHODS.ONLINEORDER
    ) {
      setProductEdited(false);
    } else if (
      quantityInCart > 0 &&
      count === quantityInCart &&
      selectedFulfillmentId !== fulfillmentTypeId
    ) {
      setProductEdited(true);
    } else if (
      quantityInCart > 0 &&
      count === quantityInCart &&
      selectedFulfillmentId === fulfillmentTypeId
    ) {
      setProductEdited(false);
    } else {
      setProductEdited(quantityInCart > 0 || productAddedToCart);
    }
    if (isSisterStoreAvailable) {
      handleQuantityChange(1, false);
    } else if (count > maxAvailableQty && maxAvailableQty > 0) {
      handleQuantityChange(maxAvailableQty, false);
    }
  };
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          const paypalMessage = document.getElementById('paypal-message');
          if (paypalMessage) {
            setPayPalIsLoaded(true);
          }
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, []);

  const handleShowReviewAnalytics = useCallback(() => {
    const { pageName = '', pageType = '', siteSection = '' } = root.utag_data || {};
    const analyticsData = {
      eventType: 'reviewsLinkInteractionPDP',
      pageName,
      pageType,
      siteSection,
      customLinkName: 'Read Reviews',
      productBrandList: [productDetails?.subBrandName ?? ''],
      productIdList: [skuDetailsData?.skuPricingAndAvailability.skuId ?? ''],
      productPartNumberList: [productDetails?.partNumber ?? ''],
      productRepositoryIdList: [productDetails?.ecommProductId ?? ''],
    };
    clickTrack(analyticsData);
  }, [productDetails, skuDetailsData]);

  useEffect(() => {
    const showReview = document.getElementById('ratingsTop');
    //listen for onclick events for showReview
    if (showReview) {
      showReview.addEventListener(
        'click',
        () => {
          handleShowReviewAnalytics();
        },
        false
      );
    }
    return () => {
      if (showReview) {
        showReview.removeEventListener('click', () => {}, false);
      }
    };
  }, [handleShowReviewAnalytics]);

  const updateCart = useCallback(
    async (count: number = props.count) => {
      const prevcartCount =
        (skuDetailsData?.quantityInCart ?? 0) > 0 ? skuDetailsData?.quantityInCart : 1;
      const productIdFromProductDetails = productDetails?.ecommProductId ?? '';
      const onSuccess = () => {
        const skuDetailsQueryOptions = {
          skuIds: [productDetails?.itemId ?? null],
          vehicleId: catalogVehicleId,
          storeNumber: storeNumber,
          isNewDealsApiEnabled,
        };
        const skuDetails = getProductSkuDetailsFromCache(queryClient, skuDetailsQueryOptions);
        const updatedSkuDetails = skuDetails?.map(
          (skuDetailItem: {
            skuPricingAndAvailability: { productId: string; skuId: string | null };
          }) => {
            if (skuDetailItem.skuPricingAndAvailability?.skuId === productDetails?.itemId) {
              const newArr = skuDetailsData?.fulfillmentOptions?.map((option) => {
                if (option.groupId === props.groupId) {
                  return { ...option, selected: true };
                } else {
                  return { ...option, selected: false };
                }
              });
              return {
                ...skuDetailItem,
                quantityInCart: count,
                fulfillmentOptions: newArr,
                itemPresentInCart: true,
              };
            }
            return skuDetailItem;
          }
        );
        const skuDetailsKey = getProductSkuDetailsKey(skuDetailsQueryOptions);
        queryClient.setQueryData(skuDetailsKey, updatedSkuDetails);
        cartButtonRef.current?.onSuccessfulAdd?.(true);
      };

      setDisableRemoveButton(true);

      const addToCartPayload = {
        skuId: productDetails?.itemId ?? '',
        productId: productIdFromProductDetails,
        quantity: count.toString(),
        fulfillmentTypeId: fulfillmentId,
        vehicleId: catalogVehicleId,
        storeNumber,
        xFusionQueryId,
      };
      await patchOrderItem(addToCartPayload);

      onSuccess();
      await props.refetchSkuDetails();
      if (sessionStorage.getItem('sister_store_update_type') === 'select_store_and_update_cart') {
        const productType = root.sessionStorage?.getItem('productType') ?? null;
        trackNearbyStoreUpdate(
          'Update_Quantity',
          'In-Stock Near You: Set Store and Add to Cart: Quantity Change',
          {
            originalPartTypeId: productDetails?.originalPartTypeId,
            quantityInCart: props.quantityInCart,
            brandName: productDetails?.subBrandName,
            corePrice: price,
            shippingType: props.shippingType,
            skuNumber: productDetails?.itemId,
            partNumber: productDetails?.partNumber ?? '',
            productPrice: price,
            productRepositoryId: productDetails?.ecommProductId,
            requestQuantity: props.quantityInCart,
            productIdList: [skuDetailsData?.skuPricingAndAvailability.skuId ?? ''],
            productPartList: [productDetails?.partTypeName ?? ''],
            storeId: storeNumber,
            storeSearchTerm: storeNumber,
            productRecommendationTypeList: productType ? 'Sponsored Product' : 'AutoZone',
          }
        );
      }
      setProductEdited(false);
      setDisableRemoveButton(false);
      const { fulfillmentOptions } = skuDetailsData ?? {};
      const fullFillmaxQuantity =
        fulfillmentOptions?.find((option) => option.groupId == props.groupId)?.maxAvailableQty ?? 0;
      const allFulfillmentOptions =
        skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions;
      const selectedFulfillmentOption = allFulfillmentOptions?.find((option) => option.selected);
      let startGroupId;

      const fulfillmentIdChange =
        fulfillmentId === FULFILLMENT_METHODS.SAMEDAY ? cartConstants.SDD : cartConstants.STH;
      const fulfillmentChange =
        props.groupId === FULFILLMENT_METHODS.STOREORDER
          ? cartConstants.BOPUS
          : fulfillmentIdChange;

      if ((skuDetailsData?.quantityInCart ?? 0) > 0) {
        startGroupId = selectedFulfillmentOption?.groupTypeId ?? -1;
      } else {
        const shipToHomeAvailable = checkAvailabilityByGroupTypeId(
          allFulfillmentOptions,
          FULFILLMENT_METHODS.ONLINEORDER
        );
        const storePickupAvailable = checkAvailabilityByGroupTypeId(
          allFulfillmentOptions,
          FULFILLMENT_METHODS.STOREORDER
        );
        const selectedShippingType = getFulfillmentOption(
          shipToHomeAvailable,
          storePickupAvailable,
          props.defaultFulfillmentOption,
          locale
        );
        startGroupId =
          selectedShippingType === cartConstants.ONLINEORDER
            ? FULFILLMENT_METHODS.ONLINEORDER
            : FULFILLMENT_METHODS.STOREORDER;
      }

      if (
        ((prevGroupIdInCart == 0 && props.groupId === startGroupId) ||
          prevGroupIdInCart === props.groupId) &&
        selectedFulfillmentOption?.fulfillmentTypeId === fulfillmentId &&
        prevcartCount !== count &&
        fullFillmaxQuantity !== 1
      ) {
        if (!sessionStorage.getItem('sister_store_update_type')) {
          trackQuantityChange(
            'pdpPageQuantityChange',
            [String(fullFillmaxQuantity)],
            [String(productDetails?.itemId)],
            [String(count)]
          );
        }

        sessionStorage.removeItem('sister_store_update_type');
      } else if (
        (prevGroupIdInCart !== props.groupId ||
          selectedFulfillmentOption?.fulfillmentTypeId !== fulfillmentId) &&
        prevcartCount !== count &&
        !(prevcartCount === 0 && count === 1) &&
        fullFillmaxQuantity !== 1
      ) {
        trackQuantityFulfillmentChange(
          fulfillmentChange,
          'pdpPageFulfillmentAndQuantityChange',
          [String(fullFillmaxQuantity)],
          [String(productDetails?.itemId)],
          [String(count)],
          'fulfillmentAndQuantityChange'
        );
        setPrevGroupIdInCart(props.groupId);
      } else if (
        prevGroupIdInCart !== props.groupId ||
        selectedFulfillmentOption?.fulfillmentTypeId !== fulfillmentId
      ) {
        trackFulfillmentChange(fulfillmentChange, 'pdpPageFulfillmentChange', [
          String(selectedFulfillmentTypeIdOriginal),
        ]);
        setPrevGroupIdInCart(props.groupId);
      }
    },
    [
      catalogVehicleId,
      fulfillmentId,
      patchOrderItem,
      prevGroupIdInCart,
      productDetails,
      queryClient,
      props,
      skuDetailsData,
      isNewDealsApiEnabled,
      price,
      storeNumber,
      xFusionQueryId,
      locale,
      selectedFulfillmentTypeIdOriginal,
    ]
  );

  const addToCart = useCallback(
    async (count: number = props.count) => {
      const productIdFromProductDetails = productDetails?.ecommProductId ?? '';
      const addToCartRequest = {
        sku_id: productDetails?.itemId ?? '',
        product_id: productIdFromProductDetails,
        quantity: String(count),
        category_id: productDetails?.categoryId ?? '',
        order_type: `${fulfillmentId}` as OrderType,
        catalog_vehicle_id: catalogVehicleId ?? '',
        store_number: String(storeNumber ?? ''),
        orderType: `${fulfillmentId}`,
        xFusionQueryId: xFusionQueryId ?? getXFusionQueryId() ?? '',
      };

      const fulfillmentIdChange =
        fulfillmentId === FULFILLMENT_METHODS.SAMEDAY ? cartConstants.SDD : cartConstants.STH;
      const fulfillmentChange =
        props.groupId === FULFILLMENT_METHODS.STOREORDER
          ? cartConstants.BOPUS
          : fulfillmentIdChange;

      const productType = root.sessionStorage?.getItem('productType');

      let analyticsData: AddToCartAnalyticsData | null = null;
      let productAvailableQuantity = 0;
      const discountedPrice = isNewDealsApiEnabled
        ? skuDealsData?.[0]?.skuDealsInfo?.discountedPrice
        : skuDetailsData?.skuPricingAndAvailability?.discountedPrice;
      const productStrikeThroughPricing = getProductStrikethroughAnalyticValue(
        skuDetailsData?.skuPricingAndAvailability,
        discountedPrice,
        strikeThroughPricingEnabled
      );

      if (sessionStorage.getItem('addOrUpdateCart') === 'true') {
        const fulfillmentOptions =
          skuDetailsData?.skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions;
        productAvailableQuantity =
          (fulfillmentOptions && getMaxAvailQtyFromOptions(fulfillmentOptions)) ?? 0;
      } else {
        const { pageName = '', pageType = '' } = root.utag_data ?? {};
        analyticsData = {
          fulfillmentChange: prevGroupId !== 0 ? fulfillmentChange : '',
          customLinkName:
            prevGroupId !== 0
              ? 'pdpPageFulfillmentChange'
              : productType === 'certona'
              ? 'PDP:Certona:Cart Addition'
              : productType === 'sponsoredProduct'
              ? 'PDP:SponsoredProduct:Cart Addition'
              : 'PDP:AutoZone:Cart Addition',
          productBrand: productDetails?.subBrandName ?? '',
          price: props.retailPrice,
          corePrice: (skuDetailsData?.skuPricingAndAvailability?.corePrice ?? 0) * count,
          originalPartTypeId: productDetails?.originalPartTypeId ?? '',
          productRepositoryId: productDetails?.ecommProductId ?? '',
          productPartNumber: productDetails?.partNumber ?? '',
          pageName: pageName,
          pageType: pageType,
          productRecommendationTypeList:
            productType === 'certona'
              ? 'Certona'
              : productType === 'sponsoredProduct'
              ? 'Sponsored Product'
              : 'AutoZone',
          productStrikeThroughPricing: productStrikeThroughPricing,
          productFulfillmentOptionSeenByCustomer: selectedFulfillmentTypeIdOriginal,
        };
      }

      const isAffinityRecommendation = sessionStorage.getItem('pdpUtagViewAffinity') === 'true';

      if (isAffinityRecommendation && analyticsData) {
        analyticsData = {
          ...analyticsData,
          productRecommendationTypeList: 'Affinity',
        };
      }

      if (addToCartRequest?.product_id && !skuDetailsData?.itemPresentInCart) {
        const onSuccess = () => {
          props.setQuantityInCart(props.count);
          props.setTrackQOHError(true);
          setProductAddedToCart(true);
        };

        const onSettled = async () => {
          if (
            sessionStorage.getItem('sister_store_update_type') === 'select_store_and_add_to_cart'
          ) {
            trackNearbyStoreUpdate(
              'In-Stock Near You: Set Store and Add to Cart',
              'In-Stock Near You: Set Store and Add to Cart',
              {
                storeId: String(storeNumber ?? ''),
                storeSearchTerm: String(storeNumber ?? ''),
                productRecommendationTypeList:
                  productType === 'sponsoredProduct' ? 'Sponsored Product' : 'AutoZone',
                originalPartTypeId: productDetails?.originalPartTypeId ?? '',
                quantityInCart: productAvailableQuantity ?? 0,
                brand: skuDetailsData?.skuPricingAndAvailability?.brandName ?? '',
                corePrice: skuDetailsData?.skuPricingAndAvailability?.corePrice ?? 0,
                skuNumber: skuDetailsData?.skuPricingAndAvailability?.skuId ?? '',
                partNumber: productDetails?.partNumber ?? '',
                productPrice: skuDetailsData?.skuPricingAndAvailability?.retailPrice ?? 0,
                productRepositoryId: productDetails?.ecommProductId ?? '',
                productIdList: [skuDetailsData?.skuPricingAndAvailability.skuId ?? ''],
                productPartList: [productDetails?.partTypeName ?? ''],
                requestQuantity: Number(productData?.quantity ?? 0),
              }
            );
          }
          setDisableRemoveButton(false);
          await props.refetchSkuDetails();
          await props.refetchProductData();
          cartButtonRef.current?.onSuccessfulAdd?.();
        };

        setDisableRemoveButton(true);
        onAddToCartCall(addToCartRequest, analyticsData, catalogVehicleId, onSuccess, onSettled);
      } else {
        router.push(routePaths.cart);
      }
    },
    [
      catalogVehicleId,
      fulfillmentId,
      onAddToCartCall,
      prevGroupId,
      productData.quantity,
      productDetails,
      props,
      router,
      skuDetailsData,
      storeNumber,
      strikeThroughPricingEnabled,
      isNewDealsApiEnabled,
      skuDealsData,
      xFusionQueryId,
      selectedFulfillmentTypeIdOriginal,
    ]
  );

  const addToCartDisabled = () => {
    if (isFetchingLocation) {
      return true;
    }

    const isMxWeb = locale === countryCodes.mx;
    const productData = skuDetailsData;
    const skuPricingAndAvailability = productData?.skuPricingAndAvailability;
    const customizeSKUs = skuPricingAndAvailability?.configurableSku;
    const retailPrice = props.retailPrice?.toString().split('.');
    const dealPrice = [retailPrice[0], retailPrice[1] ? retailPrice[1].padEnd(2, '0') : '00'];
    const havePrice = !!dealPrice.reduce(
      (totalPrice, value) => totalPrice + Number(value || '0'),
      0
    );
    const fulfillmentOptions = skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions;
    const storeSelected = productData?.storeSelected;
    const shipToHomeAvailable = checkAvailabilityByGroupTypeId(
      fulfillmentOptions,
      fulfillmentGroupTypeIds.shipToHome
    );
    const storePickupAvailable = checkAvailabilityByGroupTypeId(
      fulfillmentOptions,
      fulfillmentGroupTypeIds.storePickup
    );
    const productAvailableQuantity =
      (fulfillmentOptions && getMaxAvailQtyFromOptions(fulfillmentOptions)) ?? 0;
    const isNotAvailable = fulfillmentOptions?.every((item) => {
      return item.available === false;
    });
    const sisterStoreAvailable = fulfillmentOptions?.[0]?.sisterStoreAvailable ?? false;
    if (isNotAvailable && sisterStoreAvailable && isBopusNewMsgApplicable) {
      return false;
    }
    return (
      (isMxWeb && storeSelected && (!storePickupAvailable || productAvailableQuantity <= 0)) ||
      (!isMxWeb && customizeSKUs && !shipToHomeAvailable) ||
      (!isMxWeb && !havePrice && !customizeSKUs) ||
      (!isMxWeb && !shipToHomeAvailable && !storePickupAvailable)
    );
  };

  const onToggleClick = () => {
    const specElement = document.getElementById('product-specifications-container');
    trackReadMoreLinkPdp('productSpecifications');

    if (specElement != null) {
      const viewportOffset = specElement.getBoundingClientRect();
      props.setShouldOpenSpecsPanel?.(true);

      if (viewportOffset.top !== 0) {
        scrollToElem(viewportOffset.top + window.pageYOffset - 100);
      } else {
        handleWindowScroll(window.certona.device);
      }
    }
  };

  const handleWindowScroll = (device: string) => {
    if (device === mobile) {
      window.scroll({
        top: window.innerHeight + window.innerWidth,
        behavior: 'smooth',
      });
    } else if (device === tablet && window.orientation === 90) {
      window.scroll({
        top: window.outerWidth,
        behavior: 'smooth',
      });
    } else if (device === tablet) {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    }
  };

  const showWarrantyModal = () => {
    props.showWarrantyModal(productInfoConstants.ProductSpec);
  };

  const goToCart = () => {
    router.push(routePaths.cart);
  };

  const toggleStoreModal = () => {
    setStoreModalOpen(!storeModalOpen);
  };

  const displayDeals = () => {
    if (!pdpDealsModelOpen) {
      return null;
    }
    const mainImage = productDetails?.itemImageUrl ?? '';
    const productId = productDetails?.ecommProductId ?? '';
    const catalogRefIdNew = productDetails?.itemId ?? '';
    const skuDescription = productDetails?.itemDescription ?? '';
    return (
      <DealsModal
        openDialog={pdpDealsModelOpen}
        handleCloseModal={handleCloseModal}
        mainImage={mainImage}
        catalogRefId={catalogRefIdNew}
        productId={productId}
        productDetails={{
          productId,
          skuId: catalogRefIdNew,
          skuDescription,
        }}
      />
    );
  };

  const pdpDealsButton = () => {
    const dealAvailable = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuDealsInfo?.dealAvailable
      : skuDetailsData?.aZBadgesFlagVO?.dealAvailable;

    return (
      dealAvailable &&
      !dealDrawerPDPEnabled && (
        <Grid item xs={12} lg={12} className={styles.dealsButtonStyle}>
          <Button variant="ghost" rightArrow onClick={handleDealsModal} tabIndex={0}>
            <span className={styles.imgDealsLink}>
              <NextImage
                src={itemDetail.dealIcon.src}
                alt={itemDetail.dealIcon.alt}
                role="presentation"
                width={16}
                height={16}
              />
            </span>
            <span className={cx(azCommonStyles['az-button-text'], styles.specialDealsLink)}>
              {labels.viewDealsItem}
            </span>
          </Button>
        </Grid>
      )
    );
  };

  const pdpRatingPartWarrantyLocation = (
    catalogRefIdNew: string,
    productData?: ProductModel,
    warrantyDetails?: ProductSkuDetails | null
  ) => {
    const partNumberDisplay = productData?.partNumber ?? '';
    const skuDisplayClass = partNumberDisplay ? styles.pipeSymbolLeft : '';
    return (
      <>
        {catalogRefIdNew ? (
          <div className={styles.reviewWrapper}>
            <div
              id="ratingsTop"
              data-bv-show="rating_summary"
              data-bv-product-id={catalogRefIdNew}
              aria-label="ratings and reviews"
              onClick={ratingView}
              data-testid="ratingsTop"
            />
          </div>
        ) : null}

        <div className={styles.oemInline}>
          {oemPartNumber && (
            <ProductCrossReference oemBrandName={oemBrandName} oemPartNumber={oemPartNumber} />
          )}
          <div className={styles.productMetaWrapper}>
            <ProductMetaLabel
              className={styles.productMetaLabel}
              label={
                isBopusMexicoStoreEnabled
                  ? partNumberLabelBopusMX
                  : `${labels.lblProductPartNumber} #`
              }
              value={partNumberDisplay}
              data-testid="partNumber-container"
            />
            <ProductMetaLabel
              className={`${skuDisplayClass} ${styles.productMetaLabel}`}
              label={`${labels.lblSkuNumber} #`}
              value={productData?.itemId ?? ''}
              data-testid="product-sku-number"
            />
          </div>
        </div>
        <WarrantyBadge
          warranty={warrantyDetails?.warranty ?? productData?.warrantyMonths ?? ''}
          onClick={showWarrantyModal}
          classes={{
            root: styles.warranty,
            button: styles.warrantyButton,
            label: styles.warrantyText,
          }}
        />
      </>
    );
  };

  const pdpPriceQuantityToggle = (dealAvailable: boolean | undefined = false) => {
    const configurableSku = skuDetailsData?.skuPricingAndAvailability?.configurableSku ?? false;
    const skuPricingAndAvailability = skuDetailsData?.skuPricingAndAvailability;
    const rebatesAvailable = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuRebatesInfo?.rebatesAvailable ?? false
      : skuPricingAndAvailability?.rebatesAvailable ?? false;
    const rebateUrl = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuRebatesInfo?.rebateUrl ?? undefined
      : skuPricingAndAvailability?.rebateUrl ?? undefined;
    const rebateShortDesc = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuRebatesInfo?.rebateShortDesc ?? undefined
      : skuPricingAndAvailability?.rebateShortDesc ?? undefined;
    const storeSelected = skuDetailsData?.storeSelected;
    const availabilityInfoVO = skuDetailsData?.availabilityInfoVO;
    const eligibleForNextDay = skuDetailsData?.aZBadgesFlagVO?.dealAvailable; // only present for v2 fulfillment

    const fulFillmentOptionsV2 = skuDetailsData?.fulfillmentOptions;
    const selectedGroupFulfillmentHasDealsAvailableQty =
      skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions?.find(
        (o) => o.available && o.groupTypeId === props.groupId && o.dealsAvailableQty > 0
      );
    const availableQuantity =
      skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
        (option) => option.fulfillmentTypeId === fulfillmentId
      )?.availableQty ?? 0;
    // when SHOW_STRIKE_THROUGH_PRICING is enabled and discountPrice has value
    // show the strikethrough (save %) price format as well as hide the showDealDrawerButton
    const discountedPrice = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuDealsInfo?.discountedPrice
      : skuPricingAndAvailability?.discountedPrice;
    const showStrikeThroughPricing = strikeThroughPricingEnabled && !!discountedPrice;

    const showDealDrawerButton =
      dealDrawerPDPEnabled &&
      storeSelected &&
      !!props.groupId &&
      !!selectedGroupFulfillmentHasDealsAvailableQty &&
      !showStrikeThroughPricing;
    const showDealDrawerButtonV2 =
      dealDrawerPDPEnabled &&
      storeSelected &&
      !showStrikeThroughPricing &&
      !!props.groupId &&
      skuDealsData?.[0]?.skuDealsInfo?.dealAvailable;
    let saveNowOrRebateBadges = null;
    const dealInfo = {
      dealAvailable,
      rebatesAvailable,
      rebateUrl,
      rebateShortDesc,
    };
    if (!dealDrawerPDPEnabled) {
      if (dealAvailable || rebatesAvailable) {
        saveNowOrRebateBadges = (
          <SaveNowBadge badgeDetails={dealInfo} dealsCheck={handleDealsModal} />
        );
      }
    } else if (dealDrawerPDPEnabled && rebatesAvailable) {
      saveNowOrRebateBadges = <RebateOnlyBadge badgeDetails={dealInfo} withShortDescription />;
    }

    const lowestPrice = skuPricingAndAvailability?.lowestPrice ?? '';
    const [dollars, cents = '00'] = configurableSku
      ? lowestPrice.toString().split('.')
      : staticPrice.toString().split('.');

    const price = parseFloat(`${dollars}.${cents}`);

    const renderFulfilmentOptions = () => {
      if (locale !== countryCodes.mx && locale !== countryCodes.ptBr) {
        return (
          <FulfillmentOptionButtonsV2
            fulfillmentOptionsGroupList={fulFillmentOptionsV2 ?? []}
            itemPresentInCart={skuDetailsData?.itemPresentInCart}
            changeShippingType={changeShippingTypeV3}
            shippingType={props.shippingType}
            productName={productDetails?.itemDescription ?? ''}
            skuId={skuPricingAndAvailability?.skuId}
            isDealDrawer={false}
            quantityInCart={skuDetailsData?.quantityInCart ?? 0}
            price={price}
            count={props.count}
            sddQuantity={skuAvailableQuantities.current.sddQuantity}
            nddQuantity={skuAvailableQuantities.current.nddQuantity}
            fulfillmentId={fulfillmentId}
            disableRemoveButton={disableRemoveButton}
          />
        );
      } else {
        return fullFillmentOptionDisplay;
      }
    };
    const usFulfillmentOptions = isBopusNewMsgApplicable ? (
      <FulfillmentOptionsV2
        fulfillmentOptionsGroupList={fulFillmentOptionsV2 ?? []}
        itemPresentInCart={skuDetailsData?.itemPresentInCart}
        changeShippingType={changeShippingTypeV2}
        shippingType={props.shippingType}
        productName={productDetails?.itemDescription ?? ''}
        skuId={skuPricingAndAvailability?.skuId}
        availabilityInfoVO={availabilityInfoVO}
        isDealDrawer={false}
        quantityInCart={skuDetailsData?.quantityInCart ?? 0}
      />
    ) : (
      <OldFulfillmentOptionsV2
        fulfillmentOptionsGroupList={fulFillmentOptionsV2 ?? []}
        changeShippingType={changeShippingTypeV2}
        shippingType={props.shippingType}
        productName={productDetails?.itemDescription ?? ''}
        skuId={skuPricingAndAvailability?.skuId}
      />
    );
    const fullFillmentOptionDisplay = (
      <Grid item xs={12}>
        <div>
          {locale !== countryCodes.mx && locale !== countryCodes.ptBr ? (
            usFulfillmentOptions
          ) : (
            <FulfillmentOptionsMX
              hasDealBadge={!!saveNowOrRebateBadges}
              changeShippingType={(val: string) => props.setShippingType(val)}
              shippingType={props.shippingType}
              availabilityInfoVO={availabilityInfoVO}
              dollars={dollars}
              cents={cents}
              skuPricingAndAvailability={skuPricingAndAvailability}
              quantityInCart={skuDetailsData?.quantityInCart ?? 0}
              skuId={skuPricingAndAvailability?.skuId}
              productName={productDetails?.itemDescription ?? ''}
              storeSelected={!!storeSelected}
              itemData={productDetails}
              inline
            />
          )}
        </div>
      </Grid>
    );
    const selectedFulfillmentOption =
      skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
        (option) => option.fulfillmentTypeId === fulfillmentId
      ) ||
      skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions.find(
        (option) => option.selected
      );
    const isAvailable = selectedFulfillmentOption?.available;

    const { nddQuantity, sddQuantity } = skuAvailableQuantities.current;
    const sisterStoreAvailable =
      skuPricingAndAvailability?.skuAvailabilityInfo.fulfillmentOptions[0]?.sisterStoreAvailable ??
      false;

    return (
      <div className={styles.optionFullFillment}>
        {!hidePricing && (
          <PdpPriceQuantity
            addedToCart={skuDetailsData?.itemPresentInCart ?? false}
            dealAvailable={dealAvailable}
            configurableSku={configurableSku}
            discountedPrice={discountedPrice ?? null}
            retailPrice={props.retailPrice?.toString().split('.')}
            availProduct={skuPricingAndAvailability}
            customizeSKUs={skuDetailsData?.skuPricingAndAvailability?.configurableSku ?? false}
            count={props.count}
            staticPrice={staticPrice}
            handleQuantityChange={handleQuantityChange}
            availabilityCheck={skuAvailableQuantities.current}
            sisterStoreAvailable={sisterStoreAvailable}
            skuId={skuPricingAndAvailability?.skuId ?? ''}
            eligibleForNextDay={eligibleForNextDay}
            shippingType={props.shippingType}
            setFulfillmentId={props.setFulfillmentId}
            pageType={'productDetails'}
            saveNowOrRebateBadges={saveNowOrRebateBadges}
            fulfillmentId={fulfillmentId}
            quantityInputDisabled={!isAvailable || isFetchingLocation}
          />
        )}
        {!!props.shippingType &&
          (isNewDealsApiEnabled ? showDealDrawerButtonV2 : showDealDrawerButton) && (
            <div
              className={
                !payPalIsLoaded ? styles.dealsBadgeContainer : styles.paypalDealsBadgeContainer
              }
            >
              <div className={styles.dealsBadgeContainer}>
                <DealsDrawerButton
                  seeDealsButtonRef={seeDealsButtonRef}
                  handleClick={showBundleDealsDrawer}
                  autoFocus={autoFocus}
                  dealConfigurableLabel={
                    isNewDealsApiEnabled
                      ? skuDealsData?.[0]?.skuDealsInfo?.dealConfigurableLabel
                      : skuDetailsData?.skuPricingAndAvailability?.dealConfigurableLabel
                  }
                  customClass={styles.dealsDrawerButton}
                />
              </div>
            </div>
          )}

        {props.count > 0 && displayQuantityWarningMessage && locale !== countryCodes.mx && (
          <MaxQuantityNotificationBlock
            selectedFulfillmentID={fulfillmentId}
            availableQuantity={availableQuantity && availableQuantity > 99 ? 99 : availableQuantity}
            selectedQuantity={props.count}
            nddQuantity={nddQuantity}
            sddQuantity={sddQuantity}
          />
        )}

        <div
          className={cx({
            [styles.paypalFulfillmentSpacing]: payPalIsLoaded,
          })}
        >
          {renderFulfilmentOptions()}
        </div>
      </div>
    );
  };

  const showBundleDealsDrawer = () => {
    props.setShowBundleDeals(true);
  };

  const techNotesDisplayRender = (productData?: ProductModel) => {
    if (!productData || (!productData.techNote && !productData.quickNote)) {
      return null;
    }

    const notes =
      productData.quickNote && productData.techNote
        ? `${productData.quickNote}. ${productData.techNote}`
        : productData.quickNote || productData.techNote;

    return <PdpTechNotes techNotes={notes!} onToggleClick={onToggleClick} />;
  };

  const handleShowYmmeFitment = (hasVehicle: boolean, vehicleFits: boolean) => {
    if (hasVehicle && vehicleFits) {
      setInteractionLocation('fitmentThisFits');
      pdpCheckFits = 'Fits your vehicle';
    } else if (hasVehicle && !vehicleFits) {
      setInteractionLocation('fitmentThisDoesNotFit');
      pdpCheckFits = 'Does not Fit your X';
    } else {
      setInteractionLocation('fitmentCheckFits');
      pdpCheckFits = 'Check if it fits';
    }
    setShowYmme(true);
  };

  const renderFitmentBadge = () => {
    const { itemDescription, vehicleFitFlag, vehicleSpecificFlag, breadCrumbs, recordType } =
      productDetails ?? {};
    const vehicleFits = vehicleFitFlag ?? false;
    const vehicleSpecific = vehicleSpecificFlag ?? false;
    const hideLabel = !vehicleFits && !vehicleSpecific;
    if (recordType !== 'app' || hideLabel) {
      return null;
    }

    const { hasVehicle } = props;
    const fittingPartsURL =
      breadCrumbs?.find((item) => item.pageType === 'ProductShelf')?.seoUrl ?? routePaths.blankURL;

    return (
      <FitmentButton
        customClass={styles.fitmentButton}
        hasVehicle={hasVehicle}
        fitsVehicle={vehicleFits}
        toggleShowYmme={() => handleShowYmmeFitment(hasVehicle, vehicleFits)}
        onClickSeeFittingParts={() => {
          setPageSpecificData({
            productFindingMethod: 'Product Fitment Banner',
            pfmLocation: 'Product Fitment Banner',
            eventType: 'productFitmentBannerClick',
          });
          router.push(fittingPartsURL);
        }}
        productName={itemDescription}
      />
    );
  };

  const renderPdpProductLocation = () => {
    return (
      <div
        className={cx(azCommonStyles['az-body-2-regular'], styles.location)}
        data-testid="location-label"
      >
        <span className={azCommonStyles['az-body-2-heavy']}>
          {labels.label_DetailsPage_body_Location}{' '}
        </span>
        <span>{productDetails?.location}</span>
      </div>
    );
  };

  // Button add to cart
  const buttonAddToCart = () => {
    if (locale === countryCodes.ptBr) {
      return null;
    }

    const isMxWeb = locale === countryCodes.mx;
    const productData = skuDetailsData;
    const skuPricingAndAvailability = productData?.skuPricingAndAvailability;
    const quantityInCart = productData?.quantityInCart ?? 0;
    const addedToCart = quantityInCart > 0;
    const customizeSKUs = skuPricingAndAvailability?.configurableSku;
    const preferredVehicle = props.preferredVehicle;
    const storeSelected = productData?.storeSelected;
    const ariaLabelAddProductToCart = `${labels.lblAdd} ${productDetails?.itemDescription} ${labels.lblToCart}`;
    const ariaLabelCheckNearbyStores = `${labels.label_check_nearby_stores} ${labels.label_for} ${productDetails?.itemDescription} `;
    const ariaLabelUpdateCartWithQuantityProduct = interpolateLabel(
      labels.labelUpdateCartToProductItems,
      {
        quantity: props.count,
        product: productDetails?.itemDescription,
      }
    );

    let storeData = skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions?.find(
      (item: { groupTypeId: number; sisterStoreAvailable: boolean }) => {
        return item.groupTypeId === props.groupId;
      }
    );

    const calcButtonLabel = () => {
      if (customizeSKUs && preferredVehicle) {
        return labels.customizeYourFloorMats;
      }
      if (customizeSKUs && !preferredVehicle) {
        return labels.lblSetVehicleToSeePricing;
      }

      const isStoreOrder = fulfillmentId === FULFILLMENT_METHODS.STOREORDER;

      if (
        storeData?.sisterStoreAvailable &&
        !storeData?.available &&
        isStoreOrder &&
        checkoutNearbyStoresEnabled
      ) {
        return labels.label_check_nearby_stores;
      }
      if (addedToCart || productAddedToCart) {
        if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
          return labels.label_update_cart;
        } else {
          return labels.lblGoToCart;
        }
      }

      if (isMxWeb && !storeSelected) {
        return labels.labelSeeProductPrice;
      }
      return labels.lblAddToCart;
    };
    const calcAriaLabel = () => {
      if (customizeSKUs && preferredVehicle) {
        return labels.customizeYourFloorMats;
      }
      if (customizeSKUs && !preferredVehicle) {
        return labels.lblSetVehicleToSeePricing;
      }
      const isStoreOrder = fulfillmentId === FULFILLMENT_METHODS.STOREORDER;
      if (
        storeData?.sisterStoreAvailable &&
        !storeData?.available &&
        isStoreOrder &&
        checkoutNearbyStoresEnabled
      ) {
        return ariaLabelCheckNearbyStores;
      }
      if (addedToCart || productAddedToCart) {
        if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
          return ariaLabelUpdateCartWithQuantityProduct;
        } else {
          return labels.lblGoToCart;
        }
      }

      if (isMxWeb && !storeSelected) {
        return labels.labelSeeProductPrice;
      }
      return ariaLabelAddProductToCart;
    };

    const handleOnClick = () => {
      let storeData = skuPricingAndAvailability?.skuAvailabilityInfo?.fulfillmentOptions?.find(
        (item: { groupTypeId: number; sisterStoreAvailable: boolean }) => {
          return item.groupTypeId === props.groupId;
        }
      );
      const isStoreOrder = fulfillmentId === FULFILLMENT_METHODS.STOREORDER;
      if (storeData?.sisterStoreAvailable && !storeData?.available && isStoreOrder) {
        sisterStoreContext.toggleSisterStoreFlyout({
          skuId: skuPricingAndAvailability?.skuId ?? '',
          shippingType: props.shippingType,
          productDetails: productData,
          quantityInCart: productData?.quantityInCart ?? 0,
        });
      } else if (isMxWeb && !storeSelected) {
        return toggleStoreModal();
      } else if (customizeSKUs && preferredVehicle) {
        const availability = storeData?.availableQty.toString();
        const fulfillmentId = storeData?.fulfillmentTypeId.toString();
        trackCustomizeSkuStart(productDetails!, skuDetailsData!, fulfillmentId!, availability!);

        return props.openConfigurableSku();
      } else if (customizeSKUs && !preferredVehicle) {
        setShowYmme(true);
        return;
      } else if (productEdited && (updateCartEnabled || isBopusMexicoStoreEnabled)) {
        return updateCart();
      } else if (productAddedToCart) {
        return goToCart();
      } else {
        return addToCart();
      }
    };

    const disabled = addToCartDisabled();
    const storeAndAddToCartObj = {
      disabled,
      onClick: () => {
        handleOnClick();
      },
    };

    return (
      <div
        className={styles.prodDetailSpacing}
        data-testid={`wrapper-${(addedToCart && 'go-to-cart') || 'add-to-cart'}`}
      >
        <CartButton
          ref={cartButtonRef}
          variant="contained"
          {...storeAndAddToCartObj}
          fullWidth
          loading={props.addToCartButtonLoading}
          data-testid="cart-action-pdp"
          ariaLabel={calcAriaLabel()}
          className={cx(styles.addToCartButton, { [styles.addToCartButtonDisabled]: disabled })}
        >
          {calcButtonLabel()}
        </CartButton>
        {storeModalOpen && <LazySearchStoreModal closeStoreModal={toggleStoreModal} />}
      </div>
    );
  };

  const pdpRightContent = () => {
    const productTitle = productDetails?.itemDescription ?? '';
    const productBrand = productDetails?.brandName ?? '';
    const brandURL = productDetails?.brandUrl ?? '';
    const catalogRefIdNew = productDetails?.itemId ?? '';
    const vehicleFits = productDetails?.vehicleFitFlag ?? false;
    const dealAvailable = isNewDealsApiEnabled
      ? skuDealsData?.[0]?.skuDealsInfo?.dealAvailable
      : skuDetailsData?.aZBadgesFlagVO?.dealAvailable;

    return (
      <div>
        <div className={styles.tabletSpace}>
          <PdpTitleNotes
            productTitle={productTitle}
            productBrand={productBrand}
            brandURL={brandURL}
          />
          <SeoContent productBrand={productBrand} catalogRefIdNew={catalogRefIdNew} />
          <div
            key="ratingsTop"
            className={cx(
              azCommonStyles['az-margin-top-4xs'],
              azCommonStyles['az-margin-bottom-xs'],
              styles.warrantyLocation
            )}
          >
            {pdpRatingPartWarrantyLocation(catalogRefIdNew, productDetails, skuDetailsData)}
          </div>
          {renderFitmentBadge()}
          {productDetails?.location &&
            vehicleFits &&
            props.hasVehicle &&
            renderPdpProductLocation()}
          {props.hasVehicle && techNotesDisplayRender(productDetails)}
        </div>
        <div className={cx(styles.horizantalLine, { [styles.noPricing]: hidePricing })} />
        <div className={styles.mobileSpace}>
          {pdpPriceQuantityToggle(dealAvailable)}
          {buttonAddToCart()}
          {pdpDealsButton()}
          {displayDeals()}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const setStoreAndAddToCart = async () => {
      const { setGroupId, groupId, count } = props;
      const { fulfillmentOptions } = skuDetailsData ?? {};
      const fullFillmaxQuantity =
        fulfillmentOptions?.find((option) => option.groupId == FULFILLMENT_METHODS.STOREORDER)
          ?.maxAvailableQty ?? 0;
      props.setShippingType(cartConstants.STOREORDER);
      changeShippingTypeV2(
        cartConstants.STOREORDER,
        FULFILLMENT_METHODS.STOREORDER,
        fullFillmaxQuantity
      );
      setGroupId(FULFILLMENT_METHODS.STOREORDER);
      if (groupId === FULFILLMENT_METHODS.STOREORDER) {
        const quantity = Number(sessionStorage.getItem('quantity'));
        const sanitizedQuantity = isNaN(quantity) ? count : quantity;
        skuDetailsData?.itemPresentInCart
          ? updateCart(sanitizedQuantity)
          : addToCart(sanitizedQuantity);
        sessionStorage.removeItem('addOrUpdateCart');
        sessionStorage.removeItem('skuId');
        sessionStorage.removeItem('quantity');
        sessionStorage.removeItem('productId');
        sessionStorage.removeItem('storeNumber');
      }
    };

    if (
      sessionStorage.getItem('addOrUpdateCart') === 'true' &&
      sessionStorage.getItem('skuId') === productDetails?.itemId &&
      !sisterStoreContext.isSettingNewStore
    ) {
      setStoreAndAddToCart();
    }
  }, [
    productDetails?.itemId,
    changeShippingTypeV2,
    props,
    skuDetailsData,
    updateCart,
    addToCart,
    sisterStoreContext.isSettingNewStore,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (
        document.getElementsByClassName('bv_avgRating')?.[0] &&
        document.getElementsByClassName('bv_numReviews_text') !== null &&
        document.getElementsByClassName('bv_numReviews_text')[0]
      ) {
        const rating = (document.getElementsByClassName('bv_avgRating')[0] as HTMLElement)
          .innerText;
        const reviewCount = (
          document.getElementsByClassName('bv_numReviews_text')[0] as HTMLElement
        ).innerText;
        analyticsPDPReviewRating(rating, reviewCount);
      }

      const pageType = router.query.pageType ?? '';
      if (pageType === pageTypes.ProductDetail) {
        ratingView();
      }
    }, 1000);
  }, [router.query]);

  useEffect(() => {
    const allFulfillmentOptions =
      skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions;
    const storePickupAvailable = checkAvailabilityByGroupTypeId(
      allFulfillmentOptions,
      FULFILLMENT_METHODS.STOREORDER
    );
    const { storePickupStockLabel = '' } = skuDetailsData?.skuPricingAndAvailability ?? {};
    if (
      storePickupAvailable &&
      (storePickupStockLabel === productInfoConstants.outOfStock ||
        storePickupStockLabel === productInfoConstants.notAvailable)
    ) {
      props.changeFindProduct();
    }
  }, [props, skuDetailsData?.skuPricingAndAvailability]);

  useEffect(() => {
    skuAvailableQuantities.current = getAllAvailableQuantitiesForSku(
      skuDetailsData?.fulfillmentOptions
    );
  }, [skuDetailsData?.fulfillmentOptions]);

  useEffect(() => {
    if (props.pdpAddToCartSuccess) {
      if (!isDesktop) {
        callToCertona({
          certonaType: certonaPageType.cartAdd,
          vehicleID: catalogVehicleId || '',
          skuIds: productDetails?.itemId,
        });
      }
    }
  }, [catalogVehicleId, productDetails?.itemId, props.pdpAddToCartSuccess, isDesktop]);

  useEffect(
    () => {
      if (
        productDetails?.itemId !== prevSkuId ||
        (props.shippingType !== props.prevShippingType && !productEdited)
      ) {
        const { quantityInCart = 0, skuPricingAndAvailability } = skuDetailsData ?? {};
        const startCount = quantityInCart > 0 ? quantityInCart : 1;
        const currentPrice = getStaticFormattedPrice(
          skuPricingAndAvailability?.configurableSku,
          skuPricingAndAvailability
        );
        props.setCount(startCount);
        props.setQuantityInCart(quantityInCart);
        props.setRetailPrice(getFormattedPriceMultiplied(currentPrice, startCount));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      productDetails?.itemId,
      productEdited,
      props.shippingType,
      props.prevShippingType,
      skuDetailsData,
      prevSkuId,
    ]
  );

  useEffect(
    () => {
      if (
        !props.shippingType ||
        props.productDetailsUpdatedAt !== prevProductDetailsUpdate ||
        props.skuDetailsDataLastUpdatedAt !== prevSkuDetailsUpdate
      ) {
        const { quantityInCart = 0, skuPricingAndAvailability } = skuDetailsData ?? {};
        const startCount = quantityInCart > 0 ? quantityInCart : 1;
        const currentPrice = getStaticFormattedPrice(
          skuPricingAndAvailability?.configurableSku,
          skuPricingAndAvailability
        );
        props.setCount(startCount);
        props.setQuantityInCart(quantityInCart);
        props.setRetailPrice(getFormattedPriceMultiplied(currentPrice, startCount));

        if (!props.shippingTypeChanged) {
          setProductEdited(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      props.shippingType,
      props.productDetailsUpdatedAt,
      props.skuDetailsDataLastUpdatedAt,
      skuDetailsData,
      prevProductDetailsUpdate,
      prevSkuDetailsUpdate,
    ]
  );

  const handleFulfillmentIdUpdate = useCallback(
    (newFulfillmentId: number) => {
      props.setFulfillmentId(newFulfillmentId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!props.shippingTypeChanged && !productEdited) {
      const quantityInCart = skuDetailsData?.quantityInCart ?? 0;
      const allFulfillmentOptions =
        skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions;
      const selectedFulfillmentId = getSelectedFulfilmentForSelectedQty(
        props.count,
        allFulfillmentOptions,
        props.shippingType
      );
      if (quantityInCart > 0 && selectedFulfillmentId) {
        handleFulfillmentIdUpdate(selectedFulfillmentId);
      } else if (
        fulfillmentId === FULFILLMENT_METHODS.NEXTDAY &&
        props.count > skuAvailableQuantities.current.nddQuantity
      ) {
        handleFulfillmentIdUpdate(FULFILLMENT_METHODS.ONLINEORDER);
      }
    }
  }, [
    handleFulfillmentIdUpdate,
    props.shippingTypeChanged,
    props.count,
    props.shippingType,
    shouldShowSkeleton,
    fulfillmentId,
    skuDetailsData,
    productEdited,
  ]);

  useEffect(
    () => {
      if (
        !shouldShowSkeleton &&
        (!props.shippingType ||
          props.productDetailsUpdatedAt !== prevProductDetailsUpdate ||
          props.skuDetailsDataLastUpdatedAt !== prevSkuDetailsUpdate)
      ) {
        const quantityInCart = skuDetailsData?.quantityInCart ?? 0;
        const allFulfillmentOptions =
          skuDetailsData?.skuPricingAndAvailability.skuAvailabilityInfo.fulfillmentOptions;
        const shipToHomeAvailable = checkAvailabilityByGroupTypeId(
          allFulfillmentOptions,
          FULFILLMENT_METHODS.ONLINEORDER
        );
        const storePickupAvailable = checkAvailabilityByGroupTypeId(
          allFulfillmentOptions,
          FULFILLMENT_METHODS.STOREORDER
        );
        if (quantityInCart > 0) {
          const selectedFulfillmentOption = allFulfillmentOptions?.find(
            (option) => option.selected
          );
          const startGroupId = selectedFulfillmentOption?.groupTypeId ?? -1;
          const startShippingType = convertFulfillmentTypeToOrderType(startGroupId);
          props.setShippingType(startShippingType);
          props.setGroupId(startGroupId);

          const selectedFulfillmentId = getSelectedFulfilmentForSelectedQty(
            props.count,
            allFulfillmentOptions,
            props.shippingType
          );
          const fulfillmentIdInCart = getSelectedFulfillmentInCart(
            props.count,
            allFulfillmentOptions
          );
          const addedToCart = quantityInCart > 0;
          if (
            (fulfillmentIdInCart !== selectedFulfillmentId ||
              (fulfillmentIdInCart === undefined && selectedFulfillmentId === undefined)) &&
            !props.shippingTypeChanged &&
            !(productAddedToCart || addedToCart)
          ) {
            const storePickupFulfillmentId = storePickupAvailable
              ? FULFILLMENT_METHODS.STOREORDER
              : '';
            const availableFulfillmentId =
              startGroupId === FULFILLMENT_METHODS.ONLINEORDER && shipToHomeAvailable
                ? selectedFulfillmentId ?? FULFILLMENT_METHODS.ONLINEORDER
                : storePickupFulfillmentId;
            if (availableFulfillmentId) {
              handleFulfillmentIdUpdate(availableFulfillmentId);
              setProductEdited(true);
            }
          } else if (!sisterStoreContext.isSisterStoreFlyoutOpen) {
            handleFulfillmentIdUpdate(selectedFulfillmentId!);
            setProductEdited(false);
          }
        } else if (!props.shippingTypeChanged || props.storeChanged) {
          if (props.storeChanged) {
            props.setStoreChanged(false);
          }
          const { nddQuantity, sddQuantity } = skuAvailableQuantities.current;
          let storeData = allFulfillmentOptions?.find(
            (option) => option.groupTypeId === FULFILLMENT_METHODS.STOREORDER
          );
          const defaultShippingId = getFulfillmentId(
            shipToHomeAvailable,
            storePickupAvailable,
            props.defaultFulfillmentOption,
            nddQuantity > 0,
            sddQuantity > 0,
            storeData?.sisterStoreAvailable
          );
          const selectedShippingType = getFulfillmentOption(
            shipToHomeAvailable,
            storePickupAvailable,
            props.defaultFulfillmentOption,
            locale
          );
          handleFulfillmentIdUpdate(defaultShippingId);

          const isNotAvailable = allFulfillmentOptions?.every(
            (option) => option.available === false
          );
          const sisterStoreAvailable = allFulfillmentOptions?.[0]?.sisterStoreAvailable ?? false;
          if (isNotAvailable && sisterStoreAvailable) {
            props.setShippingType(cartConstants.STOREORDER);
          } else {
            props.setShippingType(selectedShippingType);
          }
          props.setGroupId(
            selectedShippingType === cartConstants.ONLINEORDER
              ? FULFILLMENT_METHODS.ONLINEORDER
              : FULFILLMENT_METHODS.STOREORDER
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      handleFulfillmentIdUpdate,
      prevProductDetailsUpdate,
      prevSkuDetailsUpdate,
      skuDetailsData,
      productAddedToCart,
      props.shippingType,
      props.productDetailsUpdatedAt,
      props.shippingTypeChanged,
      sisterStoreContext.isSisterStoreFlyoutOpen,
      shouldShowSkeleton,
    ]
  );

  if (!skuDetailsData && workspaceId && previewDate) {
    return null;
  }

  if (!skuDetailsData && !shouldShowSkeleton) {
    return null;
  }

  return (
    <>
      <ProductAvailabilityInfoSkeleton
        leftContent={
          shouldShowSkeleton ? (
            <LeftSectionSkeletonLoading />
          ) : (
            <>
              <ProductCarousel data={productDetails!} labels={labels} />
            </>
          )
        }
        rightContent={shouldShowSkeleton ? <RightSectionSkeletonLoading /> : pdpRightContent()}
      />
      <YMMEModal
        showYmme={showYmme}
        closeYmmeWidget={() => setShowYmme(false)}
        pageType={pdpCheckFits}
        onUpdateVehicleSuccess={() => setShowYmme(false)}
      />
    </>
  );
}
