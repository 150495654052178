/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Card, Checkbox, Text, View } from '@az/starc-ui';
import { type MappedAffinityProduct } from '../../interface';
import { useLabels } from '@/hooks/useLabels';
import styles from './styles.module.scss';
import { SmartLink as Link } from '@/utils/smartLink';
import NextImage from '@/components/NextImage';

export type RelatedPartProduct = {
  isPartSelected: boolean;
  isAvailable: boolean;
} & MappedAffinityProduct;

type Props = {
  isMobile: boolean;
  product: RelatedPartProduct;
  onChange: (event: { value?: string }) => void;
};

const labelMap = {
  currentlyViewing: 'label_ProductDisplayPage_body_CurrentlyViewing',
  priceNotAvailable: 'label_shelf_resultsList_price_not_available',
};

export const RelatedPartCard = ({ product, onChange, isMobile }: Props) => {
  const { currentlyViewing, priceNotAvailable } = useLabels(labelMap);

  const price = product.totalPrice?.toFixed(2)
    ? `$${product.totalPrice?.toFixed(2)}`
    : priceNotAvailable;
  return (
    <View
      backgroundColor={product.isMainItem && isMobile ? 'disabled' : undefined}
      padding={{ s: [product.isMainItem ? 4 : 0, 4], m: [0, 0] }}
    >
      <Card height="120px" hasHoverEffect={false}>
        <View height="120px" padding={[20.5 / 4, 2]} direction="row" align="center" justify="start">
          <View direction="row" align="center" justify="start">
            <View.Item>
              <Checkbox
                disabled={!product.isAvailable}
                className={styles.relatedPartCheckbox}
                checked={product.isPartSelected && product.isAvailable}
                value={product.skuId}
                name={product.skuId}
                onChange={(event) => onChange({ value: event.target.value })}
              />
            </View.Item>
            <View.Item gapBefore={2}>
              <View>
                <Link to={product.seoUrl || '#'}>
                  <View align="start">
                    <NextImage
                      width={78}
                      height={78}
                      src={product.productImageUrl}
                      alt={product.productName}
                    />
                  </View>
                </Link>
              </View>
            </View.Item>
          </View>
          <View.Item columns={{ s: 7, m: 6 }} gapBefore={2} grow>
            <Link to={product.seoUrl || '#'}>
              <View>
                {product.isMainItem ? (
                  <View>
                    <View.Item>
                      <Text
                        align="start"
                        variant="subtitle-bold"
                        // we can not use 'size' here due to desktop rem value being 20px
                        attributes={{ style: { fontSize: '12px' } }}
                      >
                        {currentlyViewing}
                      </Text>
                    </View.Item>
                  </View>
                ) : null}
                <View>
                  <Text
                    align="start"
                    variant="main-body"
                    maxLines={product.isMainItem ? 2 : 3}
                    attributes={{ style: { display: '-webkit-box', fontSize: '14px' } }}
                  >
                    {product.productName}
                  </Text>
                </View>
                <View>
                  <Text
                    attributes={{ style: { fontSize: '14px', marginTop: '8px' } }}
                    align="start"
                    variant="subtitle-bold"
                  >
                    {price}
                  </Text>
                </View>
              </View>
            </Link>
          </View.Item>
        </View>
      </Card>
    </View>
  );
};
