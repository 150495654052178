/**
 * Copyright 2019 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import Hidden from '../../../Hidden';
import Image from '@/components/NextImage';
import Skeleton from '../../../Skeleton/Skeleton';
import styles from './LeftSection.module.scss';
const skeletonImage = '/images/image-placeholder-skeleton-load.svg';
const skeletonThumbnails = Array(6).fill(skeletonImage);
const mobileSkeletonDots = Array(4).fill(1);

export default function Left() {
  return (
    <>
      <Skeleton width="70%" height={16} />
      <div className={styles.container} data-testid="product-availability-info-skeleton">
        <div className={styles.mainImage}>
          <Image alt="product-placeholder" src={skeletonImage} width={512} height={512} priority />
        </div>
        <Hidden className={styles.thumbnailContainer} mdUp>
          {mobileSkeletonDots.map((_, index) => (
            <div
              key={index}
              className={index === 0 ? styles.mobileEmptyDot : styles.mobileFilledDot}
            />
          ))}
        </Hidden>
        <Hidden className={styles.thumbnailContainer} smDown>
          {skeletonThumbnails.map((thumbnail, index) => (
            <Image key={index} width={52} height={52} alt="product-placeholder" src={thumbnail} />
          ))}
        </Hidden>
      </div>
    </>
  );
}
