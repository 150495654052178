/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useSelector } from 'react-redux';
import { type ReduxState } from '@/types';
import Skeleton from '@/components/Skeleton/Skeleton';
import Price, { type PriceType } from '@/components/AZCustomComponent/Price';
import InfoToolTip from '@/components/AZCustomComponent/InfoToolTip';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import azCommonStyles from '@/theme/globals.module.scss';
import { formatPrice } from '@/utils/validator/formatPrice';

const labelMap = {
  lblRegularPrice: 'label_regular_price',
  lblOrderSummaryCoreDeposit: 'label_cart_orderSummary_coreDeposit',
  lblRefundable: 'label_refundable',
  labelRefundableCoreCharge: 'label_refundable_core_charge',
  lbllineItemWhatIsIt: 'label_cart_lineItem_WhatIsIt',
  lblCoreDetails: 'pagecontent_cart_corePopup_CoreDetails',
  labelRegularPricePlusCore: 'label_regular_price_plus_core',
};

type Props = {
  totalCorePrice: number;
  isBopusMexicoStoreEnabled: boolean;
  totalPrice: string;
  dealPrice: string[];
};

const CorePriceInfoPopOver = ({
  totalCorePrice,
  isBopusMexicoStoreEnabled,
  totalPrice,
  dealPrice,
}: Props) => {
  const labels = useLabels(labelMap);
  const { isFetchingLocation } = useSelector(({ appData }: ReduxState) => appData);
  const getCorePrice = (corePrice: string) => {
    const formattedPrice = formatPrice(Number(corePrice));
    if (formattedPrice !== 0 && formattedPrice !== null) {
      return formattedPrice.split('.');
    }
  };

  const havePrice = !!dealPrice.reduce((p, c) => p + Number(c || '0'), 0);

  const coreRefundLabel = isBopusMexicoStoreEnabled
    ? `${labels.lblOrderSummaryCoreDeposit}${' '}
${labels.lblRefundable}${' '}`
    : labels.labelRefundableCoreCharge;

  if (!isFetchingLocation && !havePrice) {
    return <NoPrice />;
  }

  return (
    <div className={styles.corePriceWrapper}>
      <div className={cx(styles.corePriceRow, styles.addMargin)}>
        <div className={styles.breakdownTitle}>{labels.lblRegularPrice}</div>
        <PriceOrSkeleton
          priceType={!!totalCorePrice ? 'secondary' : 'strikethrough'}
          isFetchingLocation={isFetchingLocation}
          dealPrice={dealPrice}
          showPlus={false}
        />
      </div>
      <div className={styles.coreChargeWrapper}>
        <div className={cx(styles.corePriceRow, styles.pbottom)}>
          <div>
            <span className={styles.corePriceLabel}>{coreRefundLabel}</span>
            <InfoToolTip
              classes={{ icon: styles.infoTooltip }}
              header={labels.lbllineItemWhatIsIt}
              body={labels.lblCoreDetails}
              divStyle={{
                display: 'inline-block',
                position: 'relative',
                verticalAlign: 'middle',
                lineHeight: '1',
              }}
            />
          </div>
          <PriceOrSkeleton
            isFetchingLocation={isFetchingLocation}
            dealPrice={getCorePrice(totalCorePrice.toString()) || []}
            showPlus
            priceType="secondary"
            type="core"
          />
        </div>
        <div className={styles.corePriceRowSeperator}>
          <p>{labels.labelRegularPricePlusCore}</p>
          <PriceOrSkeleton
            isFetchingLocation={isFetchingLocation}
            dealPrice={getCorePrice(totalPrice) || null}
            showPlus={false}
            priceType="secondary"
            type="core"
          />
        </div>
      </div>
    </div>
  );
};

export default CorePriceInfoPopOver;

const PriceOrSkeleton = ({
  isFetchingLocation,
  dealPrice,
  showPlus,
  priceType,
  type = undefined,
}: {
  isFetchingLocation: boolean;
  dealPrice: string[] | null;
  showPlus: boolean;
  priceType: PriceType;
  type?: string | undefined;
}) => {
  return isFetchingLocation ? (
    <Skeleton
      className={styles.refundablePriceSkeleton}
      width={50}
      height={16}
      animation={'wave'}
    />
  ) : (
    <Price priceType={priceType} dealPrice={dealPrice} type={type} showPlus={showPlus} />
  );
};

const NoPrice = () => (
  <div data-testid="price-fragment">
    <span className={cx(azCommonStyles['az-title-5-heavy'])}>
      <Label label="label_shelf_resultsList_price_not_available" />
    </span>
  </div>
);
