/**
 * Copyright 2019-2021 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import Carousel from '@/components/Carousel';
import type {
  CarouselItem,
  ThumbnailClasses,
  ThumbnailProps,
} from '@/components/Carousel/ThumbnailComponent/types';
import type { CarouselClasses } from '@/components/Carousel/types';
import NextImage from '@/components/NextImage';
import cx from 'classnames';
import React, { type MouseEventHandler, useEffect, useRef, useState } from 'react';
import { type ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import root from 'window-or-global';
import { CarouselImage } from '../CarouselImage';
import styles from '../styles.module.scss';
import carouselStyle from './carousel.module.scss';
import { useFeatureFlag } from '@/features/globalConfig';
import { defaultVideoPoster } from '@/constants/images/defaultVideoPoster';
import sharedVariables from '@/theme/sharedVariables.module.scss';
import { mapAprimoUrlToBeRemoved } from '../utils/mapAprimoUrlToBeRemoved';
import { trackImageInteractionPDP } from '../../../utils/analytics/trackImageInteractionPDP';

type Props = {
  carouselCaption?: string;
  classes?: CarouselClasses;
  controlled?: boolean;
  thumbnailClasses?: ThumbnailClasses;
  items: CarouselItem[];
  showMediumImageBeforeInteraction: boolean;
  useMagnify?: boolean;
  handleclick?: MouseEventHandler<HTMLDivElement>;
  overLayText?: string;
  onPinchToZoomImageInit?: () => void;
} & ThumbnailProps;

export const CarouselComponent = ({
  imgTitle,
  selectedThumbnailIndex,
  onImageChange,
  groupedLayout,
  showAllThumbnails,
  verticalLayout,
  classes = {},
  thumbnailClasses = {},
  carouselCaption,
  useMagnify,
  handleclick,
  overLayText,
  controlled,
  onPinchToZoomImageInit,
  items,
  showMediumImageBeforeInteraction,
}: Props) => {
  const aprimoPDPVideosEnabled = useFeatureFlag('APRIMO_PDP_VIDEO_ENABLED') === 'true';
  const pinchToZoomImages = useRef<ReactZoomPanPinchRef[]>([]);
  const videoRefs = useRef<HTMLVideoElement[] | HTMLIFrameElement[]>([]);
  const [zoomedImages, setZoomedImages] = useState<number[]>([]);

  useEffect(() => {
    videoRefs.current.forEach((element: HTMLVideoElement | HTMLIFrameElement, index: number) => {
      if ('controls' in element) {
        element.controls = false;
      }

      if (index !== selectedThumbnailIndex) {
        if ('currentTime' in element && element.currentTime) {
          element.pause();
          element.currentTime = 0;
        } else {
          const source = element.src;
          element.src = source;
        }
      }
    });

    const timeout = setTimeout(() => {
      videoRefs.current.forEach((element: HTMLVideoElement | HTMLIFrameElement) => {
        if ('controls' in element) {
          element.controls = true;
        }
      });
    }, parseInt(sharedVariables.videoTransitionTimeMilliseconds));

    return () => clearTimeout(timeout);
  }, [selectedThumbnailIndex]);

  const onZoomStop = (ref: ReactZoomPanPinchRef, index: number) => {
    if (ref.state.scale > 1 && !zoomedImages.includes(index)) {
      setZoomedImages([...zoomedImages, index]);
    } else if (ref.state.scale <= 1 && zoomedImages.includes(index)) {
      setZoomedImages(zoomedImages.filter((imageIndex) => imageIndex !== index));
    }
  };

  const handleImageChange = (
    index: number,
    carouselItem: CarouselItem,
    previousIndex?: number | void
  ) => {
    if (typeof previousIndex !== 'undefined' && pinchToZoomImages.current[previousIndex]) {
      //reset zoom when going to a new image
      pinchToZoomImages.current[previousIndex]?.resetTransform();
      setZoomedImages(zoomedImages.filter((imageIndex) => imageIndex !== previousIndex));
    }

    onImageChange?.(index, carouselItem);
  };

  const handleThumbnailClick = (carouselItem: CarouselItem) => {
    const { enlargedMediaUrl } = carouselItem;

    const index = items.findIndex((img) => img.enlargedMediaUrl === enlargedMediaUrl);

    trackImageInteractionPDP(`PDP|${index + 1}`, `${index + 1}`);
  };

  useEffect(() => {
    setTimeout(() => {
      root.dispatchEvent(new Event('resize'));
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={carouselStyle.carouselContent}>
      <Carousel
        imgTitle={imgTitle}
        items={items}
        selectedThumbnailIndex={selectedThumbnailIndex}
        onImageChange={handleImageChange}
        handleThumbnailClick={handleThumbnailClick}
        controlled={controlled}
        groupedLayout={groupedLayout}
        showAllThumbnails={showAllThumbnails}
        verticalLayout={verticalLayout}
        classes={{
          carouselControl: carouselStyle.carouselControl,
          carouselItemContainer: styles.carouselSlidesContainer,
          ...classes,
        }}
        thumbnailClasses={thumbnailClasses}
        carouselCaption={carouselCaption}
        keepCaptionPlaceholder
      >
        {items.map((item, i) => {
          const { type, title, enlargedMediaUrl, alt } = item;
          const itemKey = `${i}-${enlargedMediaUrl ? enlargedMediaUrl : ''}`;
          if (type === 'image') {
            return (
              <CarouselImage
                key={itemKey}
                item={item}
                showMediumImageBeforeInteraction={showMediumImageBeforeInteraction && i === 0}
                priority={i === 0}
                useMagnify={useMagnify}
                handleClick={handleclick}
                overLayText={overLayText}
                itemIndex={i}
                pinchZoomProps={{
                  panning: {
                    disabled: !zoomedImages.includes(i),
                  },
                  onInit: onPinchToZoomImageInit,
                  onZoomStop: (ref: ReactZoomPanPinchRef) => onZoomStop(ref, i),
                  ref: (el: ReactZoomPanPinchRef) => (pinchToZoomImages.current[i] = el),
                }}
              />
            );
          } else if (type === 'video') {
            return (
              <div className={`${styles.imgHeight} ${carouselStyle.videoContainer}`} key={itemKey}>
                {aprimoPDPVideosEnabled ? (
                  <video
                    controls
                    id={`video_${i}`}
                    hidden={selectedThumbnailIndex !== i}
                    title={title}
                    className={carouselStyle.videoIframe}
                    ref={(el) => {
                      if (el) {
                        videoRefs.current[i] = el;
                      }
                    }}
                    // to be set back to enlargedMediaUrl only with 6.7.0s
                    src={mapAprimoUrlToBeRemoved(enlargedMediaUrl)}
                    poster={defaultVideoPoster.src}
                    preload="metadata"
                  />
                ) : (
                  <iframe
                    id={`video_${i}`}
                    title={title}
                    frameBorder="0"
                    allowFullScreen={true}
                    // @ts-expect-error fix type error
                    webkitallowfullscreen="true"
                    mozallowfullscreen="false"
                    className={carouselStyle.videoIframe}
                    src={enlargedMediaUrl}
                    loading="lazy"
                    ref={(el) => {
                      if (el) {
                        videoRefs.current[i] = el;
                      }
                    }}
                  />
                )}
              </div>
            );
          } else if (type === 'spin360') {
            return (
              <div
                key={itemKey}
                className={cx(styles.imgHeight, styles.threeSixtyContainer)}
                onClick={handleclick}
              >
                <NextImage
                  className={styles.threeSixtyCarouselImage}
                  width={700}
                  height={700}
                  src={enlargedMediaUrl || ''}
                  alt={alt}
                  priority={i === 0}
                />
              </div>
            );
          }
        })}
      </Carousel>
    </div>
  );
};
