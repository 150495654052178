/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CarouselItem } from '@/components/Carousel/ThumbnailComponent/types';
import { Fade } from '@/components/Fade/Fade';
import azCommonStyles from '@/theme/globals.module.scss';
import cx from 'classnames';
import React, { type MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
  type ReactZoomPanPinchProps,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import carouselStyles from '../styles.module.scss';
import styles from './styles.module.scss';
import NextImage, { blurDataURLPlaceholder } from '@/components/NextImage';

type PinchZoomImageProps = {
  item: CarouselItem;
  handleClick?: MouseEventHandler<HTMLDivElement>;
  pinchZoomProps?: ReactZoomPanPinchProps;
  overLayText?: string;
  priority?: boolean;
  showMediumImageBeforeInteraction: boolean;
  itemIndex: number;
  imageSrc: string;
};

export const PinchZoomImage = ({
  item,
  imageSrc,
  handleClick,
  pinchZoomProps,
  overLayText,
  priority,
  itemIndex,
}: PinchZoomImageProps) => {
  const [showOverlayText, setShowOverlayText] = useState(!!overLayText);
  const overlayTextTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (overLayText) {
      overlayTextTimerRef.current = setTimeout(() => {
        setShowOverlayText(false);
      }, 2000);
    }

    return () => {
      if (overlayTextTimerRef.current) {
        clearTimeout(overlayTextTimerRef.current);
      }
    };
  }, [overLayText]);

  return (
    <div className={cx(carouselStyles.imgHeight, styles.pinchZoomContainer)} onClick={handleClick}>
      <TransformWrapper
        wheel={{
          disabled: false,
        }}
        smooth={false}
        maxScale={4}
        {...pinchZoomProps}
      >
        <TransformComponent
          wrapperClass={styles.pinchZoomWrapper}
          contentClass={styles.pinchZoomContent}
        >
          <NextImage
            width={700}
            height={700}
            src={imageSrc ?? ''}
            alt={item.alt}
            priority={priority}
            data-index={itemIndex}
            data-testid="carousel-mobile-pinchzoom-mobile"
            className={styles.carouselImage}
            placeholder={priority ? 'blur' : undefined}
            blurDataURL={priority ? blurDataURLPlaceholder : undefined}
          />
          <Fade open={!!overLayText && showOverlayText} duration={500}>
            <div className={styles.overlayTextContainer}>
              <div className={cx(azCommonStyles['az-caption'], styles.overLayText)}>
                {overLayText}
              </div>
            </div>
          </Fade>
        </TransformComponent>
      </TransformWrapper>
    </div>
  );
};
