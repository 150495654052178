/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { fulfillmentLabels } from '../../constants/fulfillmentConstants';
import type { FulfillmentOption } from '@/types/reduxStore/skuDetails';
import styles from './styles.module.scss';
import { OrderWithinHrsMinArrivesMessage } from './FulfillmentCutoff';
import { Label } from '@/features/i18n';
import { useLabel } from '@/hooks/useLabels';
import NextImage from '@/components/NextImage';
import { nddIcon } from '../../constants/images/nddIcon';
import cx from 'classnames';
import { useMonetateDecisionFlag } from '@/features/kibo/api/getKiboDecision';

export default function NextDayOption({ option }: { option: FulfillmentOption }) {
  const nddLabel = useLabel('label_ndd_eligible');
  const inStockLabel = useLabel('label_cart_lineItem_InStock');
  const showNewFfmText = useMonetateDecisionFlag('displayNewFulfillmentText');

  return (
    <>
      <div
        className={cx(styles.NextDaylistItemContainer, { [styles.unsetHeight]: !showNewFfmText })}
        data-testid="next-day-delivery"
      >
        {showNewFfmText ? (
          <div data-testid="next-day" className={styles.inStockLabel}>
            <div className={styles.inStockLabelContainer}>
              <div className={styles.tickmarkContainer}>
                <img src="/images/green-tickmark.svg" alt="" />{' '}
              </div>
              <span>
                <span className={styles.successBoldedMessage}>{inStockLabel}</span>
                <span> | </span>
                {option.available &&
                option.estimatedDeliveryDate &&
                option.fullfillmentCutOffDate ? (
                  <OrderWithinHrsMinArrivesMessage
                    estDeliveryDateString={option.estimatedDeliveryDate}
                    cutOffDateString={option.fullfillmentCutOffDate}
                    arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_hrsmin_tail}
                    displayAmPmFormat={false}
                    shippingArriveBy={fulfillmentLabels.label_shipping_arriveBy}
                    isNdd={true}
                  />
                ) : (
                  <span className={styles.deliveryMessage}>{option.availableLabel}</span>
                )}
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.listItemIcon}>
              <NextImage
                role="presentation"
                src={nddIcon.src}
                alt={nddIcon.alt}
                height="16"
                width="32"
              />
            </div>
            <div className={styles.listItemDetails}>
              <div className={styles.listItemHeader}>
                <span data-testid="next-day" className={styles.nextDayHeader}>
                  <Label label={nddLabel} />
                </span>
              </div>
              {option.available && option.estimatedDeliveryDate && option.fullfillmentCutOffDate ? (
                <OrderWithinHrsMinArrivesMessage
                  estDeliveryDateString={option.estimatedDeliveryDate}
                  cutOffDateString={option.fullfillmentCutOffDate}
                  arrivesByLabel={fulfillmentLabels.label_shipping_cutoff_hrsmin_tail}
                  displayAmPmFormat={false}
                  shippingArriveBy={fulfillmentLabels.label_shipping_arriveBy}
                  isNdd={true}
                />
              ) : (
                <span className={styles.deliveryMessage}>{option.availableLabel}</span>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
