/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { CarouselItem } from '@/components/Carousel/ThumbnailComponent/types';
import type { Locale } from '@/types/i18n';
import { type ThreeSixtyInventoryItem } from '../../../interface';
import { format360Url } from '../../../utils/format360Url';

export const map360CarouselItem = (
  locale: Locale,
  threeSixtyInventoryItem?: ThreeSixtyInventoryItem,
  alt?: string
): CarouselItem[] => {
  if (threeSixtyInventoryItem) {
    const { thumbnail, uri: threeSixtyImagesUrl } = threeSixtyInventoryItem || {};
    return [
      {
        alt,
        enlargedMediaUrl: format360Url(thumbnail, locale, 640),
        thumbnail: format360Url(thumbnail, locale, 70),
        type: 'spin360',
        threeSixtyImagesUrl,
      },
    ];
  }
  return [];
};
