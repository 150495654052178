/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { useState, useRef, useEffect } from 'react';
import { Label } from '@/features/i18n';
import { useLabels } from '@/hooks/useLabels';
import { useAddAffinityItemsToCartMutation } from '../../api/postAddAffinityItemsToCart';
import { useAffinityRelatedProducts } from '../../api/getAffinityRelatedProducts';
import { View, Text, Card, InfoCircle, Icon, Hidden, CheckmarkCircle, Loader } from '@az/starc-ui';
import styles from './styles.module.scss';
import CartButton, {
  type CartButtonRef,
  SUCCESS_ANIMATION_DURATION,
} from '@/components/AZCustomComponent/CartButton';
import { useRouter } from 'next/router';
import { useProductDetails } from '@/features/product/api/getProductDetails';
import { useProductSkuDetails } from '@/features/product/api/getProductSkuDetails';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { RelatedPartCard, type RelatedPartProduct } from '../RelatedPartCard';
import { CompleteTheJobDescription } from '../CompleteTheJobDescription';
import { ShowAllItemsBtn } from '../ShowAllItemsBtn';
import NotificationBlock from '@/components/AZCustomComponent/NotificationBlock';
import { getErrorMessage } from '@/utils/errorsHandling';

const labelMap = {
  lblCompleteTheJob: 'label_ProductDisplayPage_body_CompleteTheJob',
  lblProductQuantityText: 'label_ProductDisplayPage_body_ProductQuantityText',
  lblItems: 'label_checkout_shipping_Items',
  lblItem: 'label_checkout_shipping_Item',
  lblAdd: 'label_ProductDisplayPage_body_Add',
  lblToCart: 'label_ProductDisplayPage_body_ToCart',
  lblGoToCart: 'label_CategoryPage_body_GoToCart',
  lblSubtotal: 'label_order_confirmation_sub_total',
  lblAddedToCart: 'label_CartButton_Added_To_Cart',
  lblFeatureNotAvailable: 'label_FeatureNotAvailable',
};

// Displayed on desktop when 3x2 grid is not full
const SkeletonCard = () => (
  <Card
    hasHoverEffect={false}
    attributes={{
      'data-testid': 'affinity-skeleton-card',
      style: { boxShadow: 'none', backgroundColor: 'var(--st-color-disabled)' },
    }}
    height="120px"
  >
    {' '}
  </Card>
);

export const AffinityRelatedProductsBlockV2 = ({
  shippingType,
  fulfillmentId,
  refetchSkuDetails,
  itemsToDisplay,
}: {
  shippingType: string;
  fulfillmentId: number;
  refetchSkuDetails: () => void;
  itemsToDisplay?: number;
}) => {
  const labels = useLabels(labelMap);
  const router = useRouter();
  const cartButtonRef = useRef<CartButtonRef>(null);
  const [parts, setParts] = useState<RelatedPartProduct[]>([]);
  const [showAllParts, setShowAllParts] = useState(false);
  const { amountOfSelectedItems, itemsTotalPrice } = parts.reduce(
    (total, i) =>
      i.isPartSelected && i.isAvailable
        ? {
            amountOfSelectedItems: ++total.amountOfSelectedItems,
            itemsTotalPrice: total.itemsTotalPrice + (i.totalPrice ?? 0),
          }
        : total,
    {
      itemsTotalPrice: 0,
      amountOfSelectedItems: 0,
    }
  );

  const {
    mutate: addToCartMutation,
    isLoading: isLoadingAddToCart,
    isSuccess: addAffinityToCartSuccess,
    error: addAffinityToCartError,
  } = useAddAffinityItemsToCartMutation({
    onSuccess: () => {
      cartButtonRef.current?.onSuccessfulAdd();
      refetchSkuDetails();
    },
  });
  const { data: affinityRelatedProductsData, isLoading: isLoadingAffinity } =
    useAffinityRelatedProducts({ itemsToDisplay });
  const { data: productDetailsData, isLoading: isLoadingProductDetails } = useProductDetails();
  const { data: skuDetailsData, isLoading: isLoadingSkuDetails } = useProductSkuDetails({
    enabled: Boolean(productDetailsData?.product?.itemId),
    skuIds: [productDetailsData?.product?.itemId ?? ''],
  });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.only('md'));
  const { data: headerData } = useHeaderData();
  const storeNumber = headerData?.storeNumber;
  const catalogVehicleId = headerData?.vehicleMap.catalogVehicleId;
  const addedAffinityToCart = useRef<boolean>(false);

  // 'Added to Cart' should appear at the same time as button reads 'Go to Cart'
  const [showAddedToCart, setShowAddedToCart] = useState(addAffinityToCartSuccess);
  useEffect(() => {
    if (addAffinityToCartSuccess) {
      setTimeout(() => {
        setShowAddedToCart(true);
      }, SUCCESS_ANIMATION_DURATION);
    }
  }, [addAffinityToCartSuccess]);
  // Populates parts list with data from all sources required to add to cart
  useEffect(() => {
    if (productDetailsData && skuDetailsData?.[0] && affinityRelatedProductsData) {
      setParts([
        {
          availableFulfillmentIds: skuDetailsData[0].fulfillmentOptions.map(
            (fulfillmentOption) => fulfillmentOption.groupId
          ),
          totalPrice: skuDetailsData[0]?.skuPricingAndAvailability?.retailPrice ?? null,
          isPartSelected: addedAffinityToCart.current
            ? parts[0].isPartSelected
            : !skuDetailsData[0]?.itemPresentInCart,
          isAvailable:
            Boolean(skuDetailsData[0]?.fulfillmentOptions?.some((option) => option.available)) &&
            // Check that price is not null or 0
            Boolean(skuDetailsData[0]?.skuPricingAndAvailability?.retailPrice),
          productImageUrl: productDetailsData.product?.itemImageUrl ?? '',
          productName: productDetailsData.product?.itemDescription ?? '',
          skuId: productDetailsData.product?.itemId ?? '',
          productId: productDetailsData.product?.ecommProductId ?? '',
          isMainItem: true,
          originalPartTypeId: productDetailsData.product?.originalPartTypeId ?? '',
        },
        ...affinityRelatedProductsData.map(
          ({
            availableFulfillmentIds,
            totalPrice,
            productImageUrl,
            productName,
            skuId,
            seoUrl,
            productId,
            originalPartTypeId,
          }) => ({
            availableFulfillmentIds,
            totalPrice,
            productImageUrl,
            productName,
            skuId,
            seoUrl,
            productId,
            isAvailable: totalPrice !== null,
            isPartSelected: true,
            isMainItem: false,
            originalPartTypeId,
          })
        ),
      ]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetailsData, skuDetailsData, affinityRelatedProductsData]);

  const addToCartButtonText = `${labels.lblAdd} ${amountOfSelectedItems} ${
    amountOfSelectedItems > 1 ? labels.lblItems : labels.lblItem
  } ${labels.lblToCart}`;

  const navigateToCart = () => {
    void router.push('/cart');
  };
  const handleAddToCart = () => {
    addedAffinityToCart.current = true;
    const selectedAndAvailableParts = parts.filter(
      ({ isPartSelected, isAvailable }) => isAvailable && isPartSelected
    );
    addToCartMutation({
      selectedAndAvailableParts,
      allPartsAmount: parts.length,
      fulfillmentId,
      storeNumber,
      catalogVehicleId,
      shippingType,
    });
  };
  return (
    <View className={styles.relatedProductsContainer} attributes={{ style: { fontSize: '16px' } }}>
      <View padding={{ s: [0, 4.5], l: [0, 3.5] }}>
        <Text
          className={styles.relatedProductsHeader}
          variant="display-2"
          textCase="uppercase"
          attributes={{ style: { fontSize: '28px' } }}
          lineHeight="140"
          weight="medium"
        >
          {labels.lblCompleteTheJob}
        </Text>
      </View>
      <View padding={{ s: [4.5, 0], m: [4, 4] }}>
        {Boolean(addAffinityToCartError) && (
          <NotificationBlock
            type="error"
            message={
              <Label
                label={getErrorMessage(addAffinityToCartError) ?? labels.lblFeatureNotAvailable}
              />
            }
          />
        )}
        <View align="stretch" direction={{ s: 'column', m: 'row' }} gap={2}>
          <View.Item
            className={styles.ctjDescription}
            gapBefore={{ s: 0, m: 4 }}
            columns={{ s: 12, m: 3 }}
          >
            <CompleteTheJobDescription />
          </View.Item>
          <View.Item columns={{ s: 12, m: 9 }}>
            <View direction={{ s: 'column', m: 'row' }} gap={2}>
              {!parts.length &&
                (isLoadingAffinity || isLoadingProductDetails || isLoadingSkuDetails) && (
                  <View.Item grow>
                    <Loader variant="spinner" />
                  </View.Item>
                )}
              {parts.length &&
                parts.map((product, i) => (
                  <Hidden key={product.skuId} hide={i > 2 && isMobile && !showAllParts}>
                    {/* TODO: remove key when STARC resolves https://gitlab.autozone.com/it/open-interface/starc/-/issues/22 */}
                    <View.Item key={product.skuId} columns={{ s: 12, m: 6 }}>
                      <RelatedPartCard
                        isMobile={isMobile}
                        product={product}
                        onChange={({ value }) =>
                          setParts((prevParts) =>
                            prevParts.map((part) => {
                              if (part.skuId === value) {
                                part.isPartSelected = !part.isPartSelected;
                              }
                              return part;
                            })
                          )
                        }
                      />
                    </View.Item>
                  </Hidden>
                ))}
              {!isMobile && parts.length % 2 !== 0 && (
                <View.Item columns={6}>
                  <SkeletonCard />
                </View.Item>
              )}
              <Hidden hide={{ s: parts.length <= 3, m: true }}>
                <ShowAllItemsBtn
                  expanded={showAllParts}
                  numParts={parts.length}
                  onClick={() => setShowAllParts((a) => !a)}
                />
              </Hidden>
            </View>
          </View.Item>
        </View>
        <View
          padding={{ s: [0, 4], m: [4, 0] }}
          direction={{ s: 'column', m: 'row' }}
          gap={{ s: 4.5, m: 2 }}
          align="center"
        >
          <View.Item gapBefore={4} columns={{ s: 12, l: 5 }}>
            <View padding={{ s: 2, m: 0 }} direction="row" gap={2}>
              <View padding={{ s: [1, 0], m: 0 }}>
                <Icon
                  size={{ s: 3, m: 3.5 }}
                  svg={InfoCircle}
                  color="on-secondary"
                  className={styles.exclamationIcon}
                />
              </View>
              <View.Item grow>
                <Text
                  lineHeight="125"
                  color="on-secondary"
                  // we can not use 'size' here due to desktop rem value being 20px
                  attributes={{ style: { fontSize: '12px' } }}
                >
                  {labels.lblProductQuantityText}
                </Text>
              </View.Item>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, m: 8, l: 4 }}>
            <View align="center" padding={{ s: 0, m: 2 }} direction="row">
              <View.Item grow>
                <Text
                  align={isMobile ? 'center' : isTablet || showAddedToCart ? 'start' : 'end'}
                  attributes={{ style: { fontSize: '18px' } }}
                  weight="bold"
                >
                  {labels.lblSubtotal}: ${itemsTotalPrice.toFixed(2)}
                </Text>
              </View.Item>
              <Hidden hide={!showAddedToCart}>
                {/* TODO: remove key when STARC resolves https://gitlab.autozone.com/it/open-interface/starc/-/issues/22 */}
                <View.Item key="addedToCart" grow={{ s: true, m: false }}>
                  <View direction="row" justify="center" align="center" gap={2}>
                    <Icon svg={CheckmarkCircle} color="success" />
                    <Text attributes={{ style: { fontSize: '16px' } }} weight="bold">
                      {labels.lblAddedToCart}
                    </Text>
                  </View>
                </View.Item>
              </Hidden>
            </View>
          </View.Item>
          <View.Item columns={{ s: 12, m: 4, l: 3 }}>
            <CartButton
              ref={cartButtonRef}
              variant="contained"
              emphasis="low"
              fullWidth
              data-testid="cart-action-pdp-affinity"
              disabled={amountOfSelectedItems === 0}
              onClick={addAffinityToCartSuccess ? navigateToCart : handleAddToCart}
              loading={isLoadingAddToCart}
              customClass={styles.cartButton}
            >
              {addAffinityToCartSuccess ? labels.lblGoToCart : addToCartButtonText}
            </CartButton>
          </View.Item>
        </View>
      </View>
    </View>
  );
};
