/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import { parseUrl } from '@/utils/urlHelpers';
import { isEmptyObject } from '@/utils/common';

type UseHandleRedirectProps = {
  redirectPath?: string;
};

export const useHandleRedirect = ({ redirectPath }: UseHandleRedirectProps) => {
  const router = useRouter();

  useEffect(() => {
    if (!redirectPath) {
      return;
    }

    const updatedRedirect =
      router.query.searchText && !redirectPath.includes('searchText')
        ? `${redirectPath}?searchText=${router.query.searchText as string}`
        : router.query.filterByKeyWord &&
          !redirectPath.includes('filterByKeyWord') &&
          !redirectPath.includes('searchText')
        ? `${redirectPath}?searchText=${router.query.filterByKeyWord as string}`
        : redirectPath;

    const parsedPath = parseUrl(updatedRedirect);
    let searchParams = {};
    let sanitizedPath = updatedRedirect;

    if (!isEmptyObject(parsedPath?.query)) {
      searchParams = parsedPath.query;
      sanitizedPath = parsedPath.url;
    }

    const hash = router.asPath.includes('#') ? router.asPath.split('#')[1] : '';

    void Router.replace(
      {
        pathname: sanitizedPath,
        query: searchParams,
        hash,
      },
      undefined
    );
  }, [redirectPath, router.asPath, router.query]);
};
