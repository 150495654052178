/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { requestBaseURL } from '@/config/serviceAPI';
import { getAxios } from '@/lib/axios';
import { type AxiosInstance } from 'axios';
import { type QueryClient, type QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { type Video, type VideosResponse } from '../interface';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

const URL = `${requestBaseURL}/ecomm/b2c/v1/tvpage/info`;

const selector = (data: VideosResponse, skuId: string): Video[] => {
  if (data[skuId]) {
    return data[skuId].slice().sort((a, b) => a.sortOrder - b.sortOrder);
  }

  return [];
};

const getProductVideos = async (
  { queryKey }: QueryFunctionContext<ReturnType<typeof getProductVideosKey>>,
  axiosInstance?: AxiosInstance
) => {
  const [, { skuId }] = queryKey;

  if (skuId == null) {
    throw new Error("An 'skuId' wasn't passed");
  }

  const response = await getAxios(axiosInstance).get<VideosResponse>(`${URL}?skuId=${skuId}`);

  return selector(response.data, skuId);
};

export const getProductVideosKey = (skuId: string | null) => ['productVideos', { skuId }] as const;

export const useProductVideos = (skuId: string | null, enabled?: boolean) => {
  const locale = useLocale();
  return useQuery({
    enabled: Boolean(skuId) && locale === countryCodes.us && enabled,
    queryKey: getProductVideosKey(skuId),
    queryFn: getProductVideos,
  });
};

export const prefetchProductVideos = async (
  queryClient: QueryClient,
  skuId: string | null,
  axiosInstance: AxiosInstance
) => {
  return queryClient.prefetchQuery({
    queryKey: getProductVideosKey(skuId),
    queryFn: (context: QueryFunctionContext<ReturnType<typeof getProductVideosKey>>) =>
      getProductVideos(context, axiosInstance),
  });
};
