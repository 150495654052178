/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { useMemo } from 'react';
import ProductDetailSpecification, {
  type ProductDetailSpecificationProps,
} from '@/components/ProductDetail/ProductDetailSpecification';
import { STARCTypography } from '@/components/STARCTypography';
import { CMSBanner } from '@/features/contentstack/components/CMSBanner/CMSBanner';
import { CMSCertona } from '@/features/contentstack/components/CMSCertona/CMSCertona';
import {
  CMSCompleteTheJob,
  type CMSCompleteTheJobProps,
} from '@/features/contentstack/components/CMSCompleteTheJob/CMSCompleteTheJob';
import { CMSSpacer } from '@/features/contentstack/components/CMSSpacer/CMSSpacer';
import type {
  FormattedContentStackDefaultPDP,
  FormattedContentStackPremiumPDP,
  ContentStackCertona,
} from '@/features/contentstack/interface';
import { CMSSEOComparisonChart } from '@/features/contentstack/components/CMSSEOComparisonChart/CMSSEOComparisonChart';
import { CMSExperienceInjector } from '@/features/contentstack/components/CMSExperienceInjector/CMSExperienceInjector';
import { BuyersGuide } from '../BuyersGuide';
import { Hidden, View } from '@az/starc-ui';
import { CMSModularHandler as CMSLPModularHandler } from '../../../../features/landingPage/components/CMSModularHandler';
import { CMSPDPYmme } from './CMSPDPYmme';
import { BazaarVoice } from '../BazaarVoice';
import {
  ScrolledPartCardSkeleton,
  type CertonaBlockProps,
  type AddCertonaItemObjectType,
} from '@/features/certona';
import { CMSBreadcrumbs } from './CMSBreadcrumbs';
import { default as CustomHidden } from '@/components/Hidden';
import { useFeatureFlag } from '@/features/globalConfig';
import { filterCTJBlocks } from '../../utils/filterCTJBlocks';
import { prioritizeLCP } from '../../utils/prioritizeLCP';

type Props = {
  content:
    | FormattedContentStackDefaultPDP['pageBody']
    | FormattedContentStackPremiumPDP['pageBody'];
  ProductDetails: JSX.Element;
  certonaType: 'accessoriesproduct' | 'pencilpdp' | 'pdp';
  'data-testid'?: string;
  canRenderComparisonChart: boolean;
  setCertonaRecommendationAddedToCart: React.Dispatch<React.SetStateAction<boolean>>;
  setAddedCertonaItemObject: React.Dispatch<React.SetStateAction<AddCertonaItemObjectType | null>>;
} & Pick<
  CertonaBlockProps,
  'certonaRecommendations' | 'onCertonaRecommendationClick' | 'setAddedCertonaItemObject'
> &
  ProductDetailSpecificationProps &
  CMSCompleteTheJobProps;

const CMSModularHandler = ({
  content,
  certonaRecommendations,
  onCertonaRecommendationClick,
  setAddedCertonaItemObject,
  ProductDetails,
  setShouldOpenSpecsPanel,
  setWarrantyModal,
  shouldOpenSpecsPanel,
  shouldShowLoadingSkeleton,
  warrantyModal,
  fulfillmentId,
  shippingType,
  canRenderComparisonChart,
  certonaType,
  setCertonaRecommendationAddedToCart,
  refetchSkuDetails,
  ...props
}: Props) => {
  const isPdpCertonaAddToCartEnabled =
    useFeatureFlag('IS_PDP_PAGE_CERTONA_ADD_TO_CART_ENABLED') === 'true';

  const hasCustomCTJ = useMemo(() => {
    return content?.some((block) => block.completethejob?.taxonomy);
  }, [content]);

  const filteredContent = useMemo(() => {
    return prioritizeLCP(filterCTJBlocks(content ?? [], hasCustomCTJ));
  }, [content, hasCustomCTJ]);

  return (
    <View attributes={{ 'data-testid': props['data-testid'] ?? '' }}>
      <STARCTypography>
        {filteredContent?.map((modularBlock, index) => {
          if (modularBlock.banner?.banner?.[0]?.asset_size) {
            const banner = modularBlock.banner.banner[0];
            const isPriorityBanner =
              banner?.image && 'priority' in banner.image && banner.image.priority;

            return (
              <View padding={[4, 0]} key={index}>
                <CMSBanner content={banner} priority={isPriorityBanner} />
              </View>
            );
          }

          if (modularBlock.buyers_guide_mobile?.enable) {
            return (
              <Hidden hide={{ m: true }} key={index}>
                <BuyersGuide />
              </Hidden>
            );
          }

          if (modularBlock.buyers_guide?.enable) {
            return (
              <Hidden hide={{ m: false, s: true }} key={index}>
                <BuyersGuide />
              </Hidden>
            );
          }

          if ('pdp_slot1' in modularBlock && modularBlock.pdp_slot1?.pdp_slot1) {
            return (
              <CMSLPModularHandler
                key={index}
                content={modularBlock.pdp_slot1.pdp_slot1[0].pdp_slot_content}
              />
            );
          }

          if ('pdp_slot2' in modularBlock && modularBlock.pdp_slot2?.pdp_slot2) {
            return (
              <CMSLPModularHandler
                key={index}
                content={modularBlock.pdp_slot2.pdp_slot2[0].pdp_slot_content}
              />
            );
          }

          if (
            'experience_injector' in modularBlock &&
            modularBlock.experience_injector?.experience_injector
          ) {
            return (
              <CMSSpacer key={index} removeVerticalSpacing>
                <CMSExperienceInjector
                  key={index}
                  content={modularBlock.experience_injector.experience_injector[0]}
                />
              </CMSSpacer>
            );
          }

          if (modularBlock.ymme?.enable) {
            return (
              <CMSSpacer key={index} removeVerticalSpacing>
                <CMSPDPYmme
                  certonaRecommendations={certonaRecommendations}
                  setCertonaRecommendationAddedToCart={setCertonaRecommendationAddedToCart}
                  setAddedCertonaItemObject={setAddedCertonaItemObject}
                />
              </CMSSpacer>
            );
          }

          if (modularBlock.bazaarvoice?.enable) {
            return (
              <CustomHidden implementation="js" smDown key={index}>
                <CMSSpacer>
                  <BazaarVoice />
                </CMSSpacer>
              </CustomHidden>
            );
          }

          if (modularBlock.bazaarvoice_mobile?.enable) {
            return (
              <CustomHidden implementation="js" mdUp key={index}>
                <CMSSpacer removeHorizontalSpacing>
                  <BazaarVoice />
                </CMSSpacer>
              </CustomHidden>
            );
          }

          const certonaContent =
            modularBlock.certona_middle_content?.certona?.[0] ??
            modularBlock.certona_bottom_content?.certona?.[0] ??
            modularBlock.certona_content_2?.certona?.[0];

          const certonaMiddleOos =
            modularBlock.certona_middle_content?.certona?.[0]?.container_id &&
            modularBlock.certona_middle_content.certona[0]?.container_id.includes(
              'accessoriesproduct'
            )
              ? 'pencilproduct2_rr'
              : modularBlock.certona_middle_content?.certona?.[0];

          const certonaBottomOos =
            modularBlock.certona_content_2?.certona?.[0].container_id &&
            !modularBlock.certona_content_2.certona[0]?.container_id.includes('accessoriesproduct')
              ? 'pencilproduct3_rr'
              : modularBlock.certona_content_2?.certona?.[0];

          const certonaContentForOos = certonaContent && {
            ...certonaContent,
            container_id: certonaMiddleOos ?? certonaBottomOos ?? '',
          };

          const certonaContentBlock =
            certonaType === 'pencilpdp' ? certonaContentForOos : certonaContent;

          const isCertonaContentPresent =
            certonaContentBlock?.container_id &&
            certonaRecommendations?.some((recommendation) =>
              recommendation?.items?.length && Array.isArray(recommendation.scheme)
                ? recommendation.scheme.includes(certonaContentBlock.container_id)
                : recommendation.scheme === certonaContentBlock?.container_id
            );

          if (certonaContentBlock && isCertonaContentPresent) {
            return (
              <CMSSpacer key={index}>
                <CMSCertona
                  content={certonaContentBlock as ContentStackCertona}
                  certonaRecommendations={certonaRecommendations}
                  onCertonaRecommendationClick={onCertonaRecommendationClick}
                  setAddedCertonaItemObject={setAddedCertonaItemObject}
                  onCertonaAddToCartClick={() => {
                    setCertonaRecommendationAddedToCart?.(true);
                  }}
                  onlyShowAvailableProductRecommendations={isPdpCertonaAddToCartEnabled}
                  LoadingSkeleton={ScrolledPartCardSkeleton}
                  checkContainerNameIncludes
                />
              </CMSSpacer>
            );
          }

          if (modularBlock.product_specification?.enable) {
            return (
              <CMSSpacer key={index} removeHorizontalSpacing>
                <ProductDetailSpecification
                  setWarrantyModal={setWarrantyModal}
                  warrantyModal={warrantyModal}
                  shouldOpenSpecsPanel={shouldOpenSpecsPanel}
                  setShouldOpenSpecsPanel={setShouldOpenSpecsPanel}
                  shouldShowLoadingSkeleton={shouldShowLoadingSkeleton}
                />
              </CMSSpacer>
            );
          }

          if (modularBlock.product_details?.enable) {
            return (
              <CMSSpacer key={index} shouldReduceSpacing>
                {ProductDetails}
              </CMSSpacer>
            );
          }

          if (modularBlock.breadcrumb?.enable) {
            return (
              <CMSSpacer key={index} shouldReduceSpacing>
                <CMSBreadcrumbs />
              </CMSSpacer>
            );
          }

          if (modularBlock.completethejob) {
            return (
              <CMSSpacer key={index}>
                <CMSCompleteTheJob
                  fulfillmentId={fulfillmentId}
                  shippingType={shippingType}
                  refetchSkuDetails={refetchSkuDetails}
                  itemsToDisplay={
                    hasCustomCTJ ? modularBlock.completethejob.items_to_display : undefined
                  }
                />
              </CMSSpacer>
            );
          }

          if (modularBlock.seo_comparision_chart?.enable && canRenderComparisonChart) {
            return (
              <CMSSpacer key={index}>
                <CMSSEOComparisonChart />
              </CMSSpacer>
            );
          }
        })}
      </STARCTypography>
    </View>
  );
};

export { CMSModularHandler };
