/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { Button, Icon, Skeleton, Text, View } from '@az/starc-ui';
import styles from './styles.module.scss';
import { NoFit } from '@az/starc-ui';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useLabels } from '@/hooks/useLabels';
import { useHeaderData } from '@/features/header/api/getHeader';
import { useEffect, useState } from 'react';

const labelsMap = {
  label_doesNotFitNotification_copy1: 'label_doesNotFitNotification_copy1',
  label_doesNotFitNotification_copy2: 'label_ProductDetailsPage_YMME_not',
  label_doesNotFitNotification_copy3: 'label_doesNotFitNotification_copy3',
  doesNotFitNotificationCTA: 'label_doesNotFitNotification_CTA',
};

export const DoesNotFitNotification = ({ href }: { href: string }) => {
  const { data: headerData } = useHeaderData();
  const [isLoading, setIsLoading] = useState(true);
  const vehicle = headerData?.vehicleMap?.vehicleName ?? '';
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isDesktopMax = useMediaQuery((theme) => theme.breakpoints.up('xl'));
  const labels = useLabels(labelsMap);

  useEffect(() => {
    // Ensuring that isDesktopMax/isMobile have values before showing content
    setIsLoading(false);
  }, []);

  const label = (
    <Text variant="large-body" className={styles.starcLargeBodyOverride}>
      {labels.label_doesNotFitNotification_copy1}
      <b>{` ${labels.label_doesNotFitNotification_copy2}`}</b>
      {` ${labels.label_doesNotFitNotification_copy3}`}
      <b>{` ${vehicle}.`}</b>
    </Text>
  );

  if (isLoading) {
    return (
      <View className={styles.removeHorizontalPadding} height={isMobile ? '100%' : '148px'}>
        <Skeleton height="100%" width="100%" />
      </View>
    );
  }

  return (
    <View className={styles.removeHorizontalPadding} height={isMobile ? '100%' : '148px'}>
      <View.Item grow>
        <View
          className={styles.doesNotFitNotificationContainer}
          height="100%"
          width="100%"
          padding={{ s: [6, 4], m: [18 / 4, 8], l: [18 / 4, 8] }}
        >
          <View.Item grow>
            <View
              direction={isMobile ? 'column' : 'row'}
              backgroundColor="secondary"
              height="100%"
              width="100%"
              align="center"
              justify={isDesktopMax ? 'start' : 'space-between'}
              padding={{ s: 4, m: [4, 8], l: [4, 8] }}
            >
              <View.Item grow={!isDesktopMax}>
                <View>
                  <View direction="row" align="center">
                    <View.Item>
                      <Icon svg={NoFit} size={8} />
                    </View.Item>
                    <View.Item gapBefore={3} grow>
                      {label}
                    </View.Item>
                  </View>
                </View>
              </View.Item>
              <View.Item gapBefore={isMobile ? 4 : 16}>
                <Button variant="pill-secondary" href={href}>
                  <Text
                    variant="small-body"
                    weight="bold"
                    className={styles.starcSmallBodyOverride}
                  >
                    {labels.doesNotFitNotificationCTA}
                  </Text>
                </Button>
              </View.Item>
            </View>
          </View.Item>
        </View>
      </View.Item>
    </View>
  );
};
