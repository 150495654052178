/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { fetchOrderDetails } from '@/actions/details';
import { requestBaseURL } from '@/config/serviceAPI';
import { clickTrack } from '@/utils/analytics/clickTrack';
import { HEADER_PRIMARY_KEY } from '@/features/header/api/getHeader';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { getAxios } from '@/lib/axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { type AxiosInstance } from 'axios';
import {
  type AddAffinityItemsToCartParams,
  type AddAffinityItemsToCartResponse,
  type AddAffinityItemsToCartVariables,
  type AffinityItemToAddToCart,
  type MappedAffinityProduct,
} from '../interface';
import root from 'window-or-global';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';
import { FULFILLMENT_METHODS } from '@/constants/fulfillmentConstants';

const pdpFulfillmentTypeMap: Record<string, string> = {
  STOREORDER: '108',
  ONLINEORDER: '101',
};

const URL = `${requestBaseURL}/ecomm/b2c/order/v4/items`;

const getMainItemFulfillmentOrGroupId = (
  selectedAndAvailableParts: MappedAffinityProduct[],
  fulfillmentId: number,
  fulfillmentInSTHGroup: boolean
) => {
  const useSelectedFulfillmentId = selectedAndAvailableParts
    .slice(1)
    .every((selectedPart) => selectedPart?.availableFulfillmentIds?.includes(fulfillmentId));
  const useSTHFulfillmentGroup = selectedAndAvailableParts
    .slice(1)
    .every((selectedPart) =>
      selectedPart?.availableFulfillmentIds?.includes(FULFILLMENT_METHODS.ONLINEORDER)
    );

  const mainFulfillmentId = useSelectedFulfillmentId
    ? fulfillmentId
    : useSTHFulfillmentGroup && fulfillmentInSTHGroup
    ? FULFILLMENT_METHODS.ONLINEORDER
    : undefined;

  return mainFulfillmentId;
};

const getCommonFulfillmentId = (selectedAndAvailableParts: MappedAffinityProduct[]) => {
  const fulfillmentMethodsArray = Object.entries(FULFILLMENT_METHODS);
  const commonFulfillmentId = fulfillmentMethodsArray.find((fulfillmentMethod) => {
    selectedAndAvailableParts
      .slice(1)
      .every((selectedPart) =>
        selectedPart?.availableFulfillmentIds?.includes(Number(fulfillmentMethod[1]))
      );
  });

  return commonFulfillmentId?.[1];
};

const postAddAffinityItemToCart = async (
  variables: AddAffinityItemsToCartVariables,
  isMxSite: boolean,
  axiosInstance?: AxiosInstance
) => {
  const { selectedAndAvailableParts, storeNumber, catalogVehicleId, fulfillmentId = 0 } = variables;
  const fulfillmentInSTHGroup =
    fulfillmentId === FULFILLMENT_METHODS.NEXTDAY ||
    fulfillmentId === FULFILLMENT_METHODS.SAMEDAY ||
    fulfillmentId === FULFILLMENT_METHODS.ONLINEORDER;
  const mainItemFulfillmentOrGroupId = getMainItemFulfillmentOrGroupId(
    selectedAndAvailableParts,
    fulfillmentId,
    fulfillmentInSTHGroup
  );
  const commonFulfillmentId = getCommonFulfillmentId(selectedAndAvailableParts);
  const mainAndAffinityItemsFulfillmentId = mainItemFulfillmentOrGroupId ?? commonFulfillmentId;

  const itemsList: AffinityItemToAddToCart[] = selectedAndAvailableParts.map(
    ({ skuId, productId, isMainItem, availableFulfillmentIds }) => {
      const partHasMainFulfillmentId = availableFulfillmentIds?.includes(fulfillmentId);
      const partShouldUseSTHGroup =
        fulfillmentInSTHGroup && availableFulfillmentIds?.includes(FULFILLMENT_METHODS.ONLINEORDER);
      return {
        fulfillmentTypeId: isMxSite
          ? `${FULFILLMENT_METHODS.STOREORDER}`
          : mainAndAffinityItemsFulfillmentId && !isMainItem
          ? `${mainAndAffinityItemsFulfillmentId}`
          : partHasMainFulfillmentId || isMainItem
          ? `${fulfillmentId}`
          : partShouldUseSTHGroup
          ? `${FULFILLMENT_METHODS.ONLINEORDER}`
          : `${availableFulfillmentIds[0]}`,
        quantity: '1',
        skuId,
        productId,
        storeNumber,
        vehicleId: catalogVehicleId,
      };
    }
  );

  const params: AddAffinityItemsToCartParams = { itemsList };

  await getAxios(axiosInstance).post<AddAffinityItemsToCartResponse>(URL, params);
};

export const useAddAffinityItemsToCartMutation = ({
  onSuccess,
}: { onSuccess?: () => void } = {}) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const locale = useLocale();
  const isMxSite = locale === countryCodes.mx;

  return useMutation({
    mutationFn: async (variables: AddAffinityItemsToCartVariables) =>
      postAddAffinityItemToCart(variables, isMxSite),
    onSuccess: async (_, variables) => {
      const { selectedAndAvailableParts, allPartsAmount, shippingType } = variables;

      dispatch(fetchOrderDetails(undefined, queryClient));
      await queryClient.invalidateQueries([HEADER_PRIMARY_KEY]);

      const { pageName = '', siteSection = '' } = root.utag_data as Record<
        string,
        string | undefined
      >;

      const productFindingMethodList: string[] = [];
      const productIdList: string[] = [];
      const productPriceList: number[] = [];
      const originalPartTypeIdList: string[] = [];
      const productFulfillmentTypeList: string[] = [];
      const productRepositoryIdList: string[] = [];

      selectedAndAvailableParts.forEach((part) => {
        // If totalPrice is null, the price is unavailable and we should not track it
        if (part.skuId && part.totalPrice !== null) {
          originalPartTypeIdList.push(part.originalPartTypeId);
          productFulfillmentTypeList.push(
            part.isMainItem
              ? pdpFulfillmentTypeMap[shippingType]
              : pdpFulfillmentTypeMap.ONLINEORDER
          );
          productFindingMethodList.push(part.isMainItem ? 'Affinity Leading Item' : 'Affinity');
          productIdList.push(part.skuId);
          productPriceList.push(part.totalPrice);
          productRepositoryIdList.push(part.productId);
        }
      });

      clickTrack({
        eventType: 'addToCart',
        productFulfillmentTypeList,
        productIdList,
        productPriceList,
        productFindingMethod: productFindingMethodList,
        productRecommendationTypeList: productFindingMethodList,
        originalPartTypeIdList,
        pfmLocation: 'Affinity: catalog_product-detail',
        customLinkName: `Affinity Cart Addition: ${selectedAndAvailableParts.length} of ${allPartsAmount}`,
        pageName,
        siteSection,
        productRepositoryIdList,
        pageType: 'catalog_product-detail',
      });

      if (onSuccess) {
        onSuccess();
      }
    },
  });
};
